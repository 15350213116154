<template>
    <div class="transitionPage">
        <div id="LoopsReturn">
            <MatCon01Header/>
            <div class="project">
                <div class="titleArea">
                    <h1 class="title">Loops + Return to the beginning</h1>
                    <p class="artistName">by Paige Frasier</p>
                </div>
                <div class="projectEmbed">
                    <h2>Loops</h2>
                    <div class="loops">
                        <img src="https://awikhigan.irlh.org/gen/wl/?id=MkrCzdRMNsOp6OWN741ntTaKgjEjRQte" alt="" class="loopsPoem">
                        <img src="https://awikhigan.irlh.org/gen/wl/?id=CQ9dAjL0Xz4A9GRmFXHsfVxVBVXo35U5" alt="" class="loopsImg">
                    </div>
                    <h2>Return to the beginning</h2>
                    <div class="return">
                        <img src="https://awikhigan.irlh.org/gen/wl/?id=Fm7HbdhI5rHzKlXOTadgtGQcqRR7jufp" alt="" class="returnImg">
                        <img src="https://awikhigan.irlh.org/gen/wl/?id=tgL6sjsWou7Z05MuMmoynL8bgPmWmdPa" alt="" class="returnPoem">
                    </div>
                    <p class="cite">The spider image was generated with : https://deepai.org/machine-learning-model/text2img</p>
                    <p class="cite">The plant image was generated with : https://rudalle.ru/en/demo</p>
                </div>

                <div class="projectInfo">
                    <h2>Artist Statement</h2>
                    <p class="artistStatement">These works are intended to be enjoyed separately. I want each reader to truly reflect and form their own opinions on the poems. The images accompanying each haiku is what I believe to be a true representation of each poem. The spider web is much like a story, it is unique and there are so many threads that tie it together. The image of the plant shows that nature is strong, it is healthy, and it will always be there even when humans are not. I think that art is what you make it, and I am excited to share my thoughts with the world, and to have each reader reflect on what they have read.</p>
                    <div class="person">
                        <a href="/mc-01/artists-curators#1">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=RfxN0eDklWZFLWnhDx3MGxItGED9Y7Nu" alt="Image of Paige Frasier">
                        </a>
                        <div>
                            <p class="name">Artist :: Paige Frasier</p>
                            <p class="Econdition">Environmental Condition :: Plant life has become aggressive toward humans</p>
                            <p class="Scondition">Storytelling Condition :: Stories do not have beginnings or ends, but are told in loops</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    </div>
</template>

<script>
    import MatCon01Header from "../../components/MatCon01-Header";
    import Footer from "../../components/Footer";
    
    export default {
        name: "LoopsReturn",
        components: {Footer, MatCon01Header}
    }
</script>

<style scoped lang="scss">
    .project {
        margin: 20px auto;
    }
    
    .projectEmbed {
        width: 80%;
        max-width: 1440px;
        margin: 0 auto;
        h2 {
            font-family: "Modern Love Caps", sans-serif;
            text-align: center;
            font-size: 28px;
            margin-bottom: 8px;
        }
    }
    .loops {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        grid-gap: 80px;
        padding-bottom: 100px;
        .loopsPoem {
            width: 350px;
        }
        .loopsImg {
            width: 400px;
            border: 5px solid black;
        }
    }
    .return {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        grid-gap: 80px;
        padding-bottom: 50px;
        .returnPoem {
            width: 350px;
        }
        .returnImg {
            width: 400px;
            border: 5px solid black;
        }
    }
    
    .cite {
        font-size: 16px;
        margin: 0;
        text-align: center;
    }
    
</style>