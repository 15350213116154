<template>
    <div class="transitionPage">
        <div id="Inhabited">
            <MatCon01Header/>
            <div class="project">
                <div class="titleArea">
                    <h1 class="title">Inhabited (free instant)</h1>
                    <p class="artistName">by Julia V. Pretsfelder</p>
                </div>
                <div class="projectEmbed">
                    <img :src="img1" alt="IN THIS MILKY WAY, IN THE FIRST GRADE,
I USED TO STARE AT THE SUN TO BLIND MYSELF,
SO I’D BE MORE READY TO DIE" @mouseleave="this.img1 = 'https://awikhigan.irlh.org/gen/wl/?id=BQcLuvpwhSpvzWwS2olqpO8PLUq3XKM9'" @mouseover="this.img1 = 'https://awikhigan.irlh.org/gen/wl/?id=ANl00Fcnnd6wZVyaPsMF39rr8KWVLEz5'">
                    <img :src="img2" alt="AND WHEN I GET USED TO STARING AT
HOSPITAL BEDS WHO ROLL THEIR NECKS LIKE CATS TO THE SUN,
FEELING BRITTLE AND ROTTING AND PRE-HISTORIC, I (RE)TURN TO" @mouseleave="this.img2 = 'https://awikhigan.irlh.org/gen/wl/?id=ZC3O0SSvrrgs0kY5OmvscISWinlH1INz'" @mouseover="this.img2 = 'https://awikhigan.irlh.org/gen/wl/?id=8L9EW4UBqO7R6NVQnUCHhxs1I7IQyJtp'">
                    <img :src="img3" alt="CRIPPLINGLY OBVIOUS FEARS OF DEATH —
CHOOSING INSTEAD TO MOURN OLD DYING DOGS
AND CRY OVER PHANTOM CATS" @mouseleave="this.img3 = 'https://awikhigan.irlh.org/gen/wl/?id=VGAigXXYA7tSVTap3YZgVjl9h7tJ9jKp'" @mouseover="this.img3 = 'https://awikhigan.irlh.org/gen/wl/?id=FgKUWqmsjoS84jhClykcXIr331SDir4S'">
                    <img :src="img4" alt="MAYBE DEATH IS WHEN GRANDDAUGHTERS BEGIN
TO INHERIT THE ANGRY BITE
IN GRANDMOTHERS’ JAWS
MAYBE DEATH IS TO BE A RED SHADOW
TO BE PUNCTUATED,
CONTAINED IN COMMAS AND PERIODS
BUT HABITABLE
FREE INSTANT" @mouseleave="this.img4 = 'https://awikhigan.irlh.org/gen/wl/?id=OP2ilDkA5fjTyzm8qq38EaaJqg0YlRZh'" @mouseover="this.img4 = 'https://awikhigan.irlh.org/gen/wl/?id=1HgYR7VhvfNk9hmv6oLkHoTfCw7ZayuA'">
                </div>
                <div class="projectInfo">
                    <h2>Artist Statement</h2>
                    <p class="artistStatement">When I first saw this prompt, I panicked a bit over all the hypotheticals
                                               it promised. Navigating so many planets without a guide or unlimited time
                                               sounds overwhelming…which is such a silly and practical feeling for
                                               something fictional and intentionally big. I’m not sure if my impulse to
                                               add more punctuation stemmed from copyediting or anxiety about
                                               vastness.</p>
                    <p class="artistStatement">I also received this prompt while visiting my grandmother who has been on
                                               bed rest in her home since December. She loved to travel and struggles to
                                               give up her independence. I was struck by the contrast between the
                                               ability to roam freely across the Milky Way and grandma being
                                               anatomically confined to her bed, home, the same family visitors, and the
                                               light classical music tv channel. The repetition of one home space and
                                               endless possibilities of instant, free travel seem similarly daunting,
                                               freeing, and limiting.</p>
                    <p class="artistStatement">I returned to edit and add to this after my childhood dog had died. His
                                               death upset me more than I had anticipated and brought me back to writing
                                               about my other grandmother dying last year followed by my grandfather’s
                                               cat who I had adopted after her death. Animal and human loss are entirely
                                               different, but they’ve both made me think about gaps in awareness about
                                               our changing and deteriorating bodies. Writing this was a way to process
                                               how we inhabit our own little Milky Ways as well as how we suffer, love,
                                               reflect, and explore before we die.</p>
                    <div class="person">
                        <a href="/mc-01/artists-curators#5">
                            <img alt="Image of Julia V. Pretsfelder" src="https://awikhigan.irlh.org/gen/wl/?id=VcO9gzDUdGSJMQjBUGSLluroAgMqVFbA">
                        </a>
                        <div>
                            <p class="name">Artist :: Julia V. Pretsfelder</p>
                            <p class="Econdition">Environmental Condition :: All planets in the milky way are habitable
                                                  and there a free instant teleportation devices between them</p>
                            <p class="Scondition">Storytelling Condition :: Everyone must publish a personal story on
                                                  their birthday</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    </div>
</template>

<script>
    import MatCon01Header from "../../components/MatCon01-Header";
    import Footer from "../../components/Footer";
    
    export default {
        name: "Inhabited",
        components: {Footer, MatCon01Header},
        data() {
            return {
                img1: "https://awikhigan.irlh.org/gen/wl/?id=BQcLuvpwhSpvzWwS2olqpO8PLUq3XKM9",
                img2: "https://awikhigan.irlh.org/gen/wl/?id=ZC3O0SSvrrgs0kY5OmvscISWinlH1INz",
                img3: "https://awikhigan.irlh.org/gen/wl/?id=VGAigXXYA7tSVTap3YZgVjl9h7tJ9jKp",
                img4: "https://awikhigan.irlh.org/gen/wl/?id=OP2ilDkA5fjTyzm8qq38EaaJqg0YlRZh"
            }
        }
    }
</script>

<style lang="scss" scoped>
    .project {
        margin: 20px auto;
    }
    
    .projectEmbed {
        width: 80%;
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        grid-gap: 80px;
        align-items: center;
        
        img {
            width: 1000px;
            max-width: 80%;
        }
    }
</style>