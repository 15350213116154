import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import VueGtag from "vue-gtag";

createApp(App).use(router, VueGtag, {
    config: {
        id: "G-3DC3FX74GM"
    },
}).mount('#app')
