<template>
    <div class="transitionPage">
        <div id="YourLifeinEmoji">
            <MatCon01Header />
            <div class="project">
                <div class="titleArea">
                    <h1 class="title">Your Life in Emoji</h1>
                    <p class="artistName">by Fred Wordie</p>
                </div>
                <div class="projectEmbed">
                    <a class="imgLink" href="http://your-life-in-emoji.salt.codes/" target="_blank">
                        <img src="https://awikhigan.irlh.org/gen/wl/?id=QgOUnmKgg7eoqUQUWL3d9KkH35uX3T71" alt="">
                    </a>
                    <p>Click to view the project site at</p>
                    <p><a href="http://your-life-in-emoji.salt.codes/" target="_blank">http://your-life-in-emoji.salt.codes/</a></p>
                </div>
                <div class="projectInfo">
                    <h2>Artist Statement</h2>
                    <p class="artistStatement">
                        Pictograms are a story telling medium that have lasted longer than any language. The earliest humans communicated and recorded their lives using crude symbols, and now many in the computer age use similarly crude Emojis to convey their emotions, ideas and desires.
                    </p>
                    <p class="artistStatement"><i>Your life in Emoji</i>, is a small web experiment that embraces this medium by challenging people to tell stories about their past, present and future through random Emoji. With each set of random emoji, I hope visitors will be triggered into remembering different things from their past, look at their present in a new light and imagine different kinds of futures.</p>
                    <div class="person">
                        <a href="/mc-01/artists-curators#8">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=4hOtjF1quAYIZVOBXaMdjyKxzkKJDSO1" alt="Image of Fred Wordie">
                        </a>
                        <div>
                            <p class="name">Artist :: Fred Wordie</p>
                            <p class="Econdition">Environmental Condition :: We can remember our entire lives from the moment we were born</p>
                            <p class="Scondition">Storytelling Condition :: There are no longer writing systems and all storytelling is oral</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
	import MatCon01Header from "../../components/MatCon01-Header";
    import Footer from "../../components/Footer";
    export default {
		name: "YourLifeInEmoji",
        components: {Footer, MatCon01Header}
    }
</script>

<style scoped lang="scss">
    .projectEmbed {
        widtH: 80%;
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-gap: 20px;
        .imgLink {
            max-width: 600px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto;
        }
        img {
            max-width: 600px;
        }
        p {
            text-align: center;
            margin: 0;
        }
    }
    
</style>