<template>
    <div class="transitionPage">
        <MatCon01Header/>
        <div class="artistList">
            <h1 class="pageTitle">Artists</h1>
            <div v-for="artist in artists" :id="artist.id" :key="artist.id" class="artist">
                <div class="left">
                    <img :src="artist.image" :style="'border: 5px solid ' + artist.color" alt="" class="propic">
                </div>
                <div class="center">
                    <h2 class="name">{{ artist.firstname }} {{ artist.lastname }}</h2>
                    <p>Project: <a :href="artist.projectLink" class="projectLink">{{ artist.project }}</a></p>
                    <div class="bio" v-html="artist.bio"></div>
                    <p v-if="artist.link" class="link">Learn more about {{ artist.firstname }} at
                        <a :href="artist.link" target="_blank">{{ artist.link }}</a></p>
                </div>
            </div>
        </div>
        <div class="curatorContainer">
            <div class="curatorList">
                <h1 class="pageTitle">Curators</h1>
                <div v-for="artist in curators" :id="artist.id" :key="artist.id" class="artist">
                    <div class="left">
                        <img :src="artist.image" :style="'border: 5px solid ' + artist.color" alt="" class="propic">
                    </div>
                    <div class="center">
                        <h2 class="name">{{ artist.firstname }} {{ artist.lastname }}</h2>
                        <p>Project: <a :href="artist.projectLink" class="projectLink">{{ artist.project }}</a></p>
                        <div class="bio" v-html="artist.bio"></div>
                        <p v-if="artist.link" class="link">Learn more about {{ artist.firstname }} at
                            <a :href="artist.link" target="_blank">{{ artist.link }}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import Footer from "@/components/Footer";
    import MatCon01Header from "../components/MatCon01-Header";
    
    export default {
        name: "Artists",
        components: {MatCon01Header, Footer},
        
        data() {
            return {
                artists: [],
                curators: []
            }
        },
        created() {
            const request = new XMLHttpRequest();
            request.open("GET", "/MatCon01-Artists.json", false);
            request.send(null)
            this.artists = (JSON.parse(request.responseText));
            
            const request2 = new XMLHttpRequest();
            request2.open("GET", "/MatCon01-Curators.json", false);
            request2.send(null)
            this.curators = (JSON.parse(request2.responseText));
        },
    }
</script>

<style lang="scss" scoped>
    $Pink: #FF70E8;
    $Yellow: #FFF170;
    $BrightBlue: #8AC6D3;
    $Teal: #5C939F;
    $PostIt: #FFECBC;
    
    .transitionPage {
        background-color: $Teal;
    }
    
    a {
        text-decoration: none;
        color: $Yellow;
        
        &:hover {
            color: #ffacfa;
        }
    }
    
    p {
        color: white;
    }
    
    .artistList, .curatorList {
        padding: 0 0 20px 0;
        width: 80%;
        max-width: 1140px;
        margin: 0 auto;
    }
    
    .curatorContainer {
        background-color: #234147;
        border-top: 5px solid pink;
    }
    
    .artistList {
        background-color: $Teal;
    }
    
    .curatorContainer {
        background-color: #234147;
        border-top: 5px solid $Pink;
    }
    
    .artist {
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        grid-gap: 40px;
        padding-bottom: 60px;
    }
    
    .name {
        font-family: "Cabin Sketch", sans-serif;
        color: $Yellow;
        font-size: 32px;
        margin-top: 0;
        margin-bottom: 8px;
        font-weight: normal;
    }
    
    .bio {
        p {
            margin-top: 0;
        }
    }
    
    .pageTitle {
        font-family: "Modern Love Caps", sans-serif;
        font-size: 42px;
        font-weight: normal;
        color: white;
        margin: 12px 0;
    }
    
    .propic {
        display: block;
        margin: 0 auto;
        max-width: 250px;
        border-radius: 20px;
    }
    
    .projectLink {
        text-decoration: underline;
    }
    
    .curatorList {
        .name {
            color: #4bffce;
        }
        
        a {
            color: #4bffce;
            
            &:hover {
                color: #ffb0fb;
            }
        }
    }
</style>

<style lang="scss">
    .bio {
        p {
            margin-top: 0;
            color: white;
        }
    }
</style>