import {createRouter, createWebHistory} from 'vue-router'

import About from '../pages/About.vue'
import MC01Exhibit from "../pages/MC01Exhibit";
import RaisinTruck from "../pages/MatCon01-Projects/RaisinTruck";
import twoheirlooms from "../pages/MatCon01-Projects/twoheirlooms";
import Artists from "../pages/Artists";
import YourLifeInEmoji from "../pages/MatCon01-Projects/YourLifeInEmoji";
import ThePearl from "../pages/MatCon01-Projects/ThePearl";
import oceanwriting from "../pages/MatCon01-Projects/OceanWriting";
import LoopsReturn from "../pages/MatCon01-Projects/LoopsReturn";
import Inhabited from "../pages/MatCon01-Projects/Inhabited";
import DoYouSee from "../pages/MatCon01-Projects/DoYouSee";
import MC01About from "../pages/MC01About";
import MC01PromptGenerator from "../pages/MC01PromptGenerator";
import Histree from "../pages/MatCon01-Projects/Histree";
import LetUsBeWeightless from "../pages/MatCon01-Projects/LetUsBeWeightless";


const routes = [
    {
        path: '/',
        name: 'Home',
        component: MC01Exhibit
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/mc-01/artists-curators',
        name: 'Artists',
        component: Artists
    },
    {
        path: '/mc-01/prompt-generator-01',
        name: 'PromptGenerator01',
        component: MC01PromptGenerator
    },
    {
        path: '/mc-01',
        name: 'MC01Exhibit',
        component: MC01Exhibit
    },
    {
        path: '/mc-01/about',
        name: 'MC01About',
        component: MC01About
    },
    {
        path: '/mc-01/the-raisin-truck-makes-raisins',
        name: 'RaisinTruck',
        component: RaisinTruck
    },
    {
        path: '/mc-01/two-heirlooms',
        name: 'twoheirlooms',
        component: twoheirlooms
    },
    {
        path: '/mc-01/your-life-in-emoji',
        name: 'YourLifeinEmoji',
        component: YourLifeInEmoji
    },
    {
        path: '/mc-01/the-pearl',
        name: 'ThePearl',
        component: ThePearl
    },
    {
        path: '/mc-01/ocean-writing',
        name: 'oceanwriting',
        component: oceanwriting
    },
    {
        path: '/mc-01/loops-return-to-the-beginning',
        name: 'LoopsReturn',
        component: LoopsReturn
    },
    {
        path: '/mc-01/inhabited',
        name: 'Inhabited',
        component: Inhabited
    },
    {
        path: '/mc-01/do-you-see-a-face-on-the-horizon',
        name: 'DoYouSee',
        component: DoYouSee
    },
    {
        path: '/mc-01/histree',
        name: 'Histree',
        component: Histree
    },
    {
        path: '/mc-01/let-us-be-weightless',
        name: 'LetUsBeWeightless',
        component: LetUsBeWeightless
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0}
    },
})

export default router
