<template>
    <div class="transitionPage">
        <div id="About">
            <h1 class="pageTitle">About Material Conditions</h1>
            <p class="description">
                Material Conditions is an ongoing series of speculative design prompts and exhibitions, curated by
                salt.codes and the Immersive Realities Lab for the Humanities. Material Conditions 01 is currently being
                curated and exhibited as a pavillion of theWrong Biennale, a decentralized global art biennale.</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "About"
    }
</script>

<style scoped lang="scss">
    $Pink: #FF70E8;
    $Yellow: #FFF170;
    $BrightBlue: #8AC6D3;
    $Teal: #5C939F;
    $PostIt: #FFECBC;

    .transitionPage {
        background-color: rgba(92, 147, 159, 0.74);
        padding: 5vw;
        @media only screen and (max-width: 800px) {
            background-color: $Teal;
            padding: 0;
        }
    }

    #About {
        width: 90%;
        max-width: 1140px;
        margin: 0 auto;
        background-color: $Teal;
        padding: 40px;
        box-shadow: 6px 6px 16px rgba(50, 50, 50, 0.5);
        @media only screen and (max-width: 800px) {
            padding: 0;
            box-shadow: 0 0 0 rgba(50, 50, 50, 0);
        }
    }

    .pageTitle {
        font-family: "Modern Love Caps", sans-serif;
        font-size: 45px;
        font-weight: normal;
        color: white;
        margin: 12px 0;
        border-bottom: 2px solid $Pink;

    }

    .title {
        font-family: "Cabin Sketch", sans-serif;
        font-size: 32px;
        color: $Yellow;
        @media only screen and (max-width: 800px) {
            font-size: 28px;
        }
    }

    .description {
        font-family: "Roboto-Slab", serif;
        color: white;
        font-size: 20px;
        font-weight: normal;
        margin: 12px 0;
        @media only screen and (max-width: 800px) {
            font-size: 18px;
        }
    }

</style>