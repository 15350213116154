<template>
    <div class="transitionPage">
        <div id="oceanwriting">
            <MatCon01Header/>
            <div class="project">
                <div class="titleArea">
                    <h1 class="title">Ocean Writing</h1>
                    <p class="artistName">by Cassandra Hradil</p>
                </div>
                <div class="projectEmbed">
                    <div class="flipContainer">
                <div id="book">
                    <div class="page page-cover page-cover-top" data-density="hard">
            <div class="page-content">
                <div class="page-image" style="background-image: url('https://awikhigan.irlh.org/gen/wl/?id=990rbWLcIhwr0P7OXhSLTPFP8J3anGBP')"></div>
            </div>
        </div>
        <div class="page" data-density="hard">
            <div class="page-content">
                <div class="page-image" style="background-image: url('https://awikhigan.irlh.org/gen/wl/?id=zzjOhBD4IAzcYPP8izgkbOP66O81u4X9')"></div>
            </div>
        </div>
        <!-- PAGES .... -->
        <div class="page" data-density="hard">
            <div class="page-content">
                <div class="page-image" style="background-image: url('https://awikhigan.irlh.org/gen/wl/?id=kqg4LzwuHJVBtcBM5bpebIs2Y5jyQRd4 ')"></div>
            </div>
        </div>
        <div class="page" data-density="hard">
            <div class="page-content">
                <div class="page-image" style="background-image: url('https://awikhigan.irlh.org/gen/wl/?id=QSDtatjTU9aMn00loZe34qabXFBAZE2Q')"></div>
            </div>
        </div>
        <div class="page" data-density="hard">
            <div class="page-content">
                <div class="page-image" style="background-image: url('https://awikhigan.irlh.org/gen/wl/?id=fUxGPU4eCJGw9XEqMaDY70QVi7YPeB6m')"></div>
            </div>
        </div>
        <div class="page" data-density="hard">
            <div class="page-content">
                <div class="page-image" style="background-image: url('https://awikhigan.irlh.org/gen/wl/?id=7aBDEA9qysPK3KZ05bI9dFtIHgySlD1B ')"></div>
            </div>
        </div>
        <div class="page" data-density="hard">
            <div class="page-content">
                <div class="page-image" style="background-image: url('https://awikhigan.irlh.org/gen/wl/?id=fVyZq6oBvdVF7SuB4HbwibIUeDcMWI5w ')"></div>
            </div>
        </div>
        <div class="page" data-density="hard">
            <div class="page-content">
                <div class="page-image" style="background-image: url('https://awikhigan.irlh.org/gen/wl/?id=vOgVTyjSxtTrlVw2KRQ8WpPNUHKKktjJ ')"></div>
            </div>
        </div>
        <div class="page" data-density="hard">
            <div class="page-content">
                <div class="page-image" style="background-image: url('https://awikhigan.irlh.org/gen/wl/?id=nlgroR4hS3a5d3f9htgLbybZZhPcXGXX ')"></div>
            </div>
        </div>
        <div class="page" data-density="hard">
            <div class="page-content">
                <div class="page-image" style="background-image: url('https://awikhigan.irlh.org/gen/wl/?id=o88dqMlR8IUoc5HiBroQMzZRh1ZBFnFR')"></div>
            </div>
        </div>
        <div class="page" data-density="hard">
            <div class="page-content">
                <div class="page-image" style="background-image: url('https://awikhigan.irlh.org/gen/wl/?id=BotZcArYcKOPpjtjBsPkUQKygFB1P164 ')"></div>
            </div>
        </div>
        <div class="page" data-density="hard">
            <div class="page-content">
                <div class="page-image" style="background-image: url('https://awikhigan.irlh.org/gen/wl/?id=xZAqOqBQ6UdGJRqzJ1n1l1l2ST4Vm2vI')"></div>
            </div>
        </div>
                </div>
            </div>
            <div class="flipInsContainer">
                <p class="flipIns" @click="flipPrevPage">Previous Page</p>
                <p class="flipIns" @click="flipNextPage">Next Page</p>
            </div>
                </div>
                <div class="projectInfo">
                    <h2>Artist Statement</h2>
                    <p class="artistStatement">
                        As our environment changes, our stories change. This story treats ocean-walking as a starting point, and tactile reading as an end point. As the protagonist is forced to migrate by an evolving climate, her storytelling also evolves. 
                    </p>
                    <p class="artistStatement">
                        This project was built rapidly in a week and a half using hand-drawn images that I cut, mounted, and scanned into each scene.
                        The aesthetic and material choices of the project reflect a making-do attitude and my current fascination with fandom culture, particularly the emerging community of creatives around HBO's <i>Our Flag Means Death</i>. It would be remiss of me not to mention the many fan animations that I watched while working on this project (as well as the ever-important Decemberists album, <i>Castaways and Cutouts</i>). The task of these animatics, to build a world at sea using only a few minutes of limited frames of animation, ultimately became the task of this project, too.
                    </p>
                    <div class="person">
                        <a href="/mc-01/artists-curators#9">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=CljrylBWroGF8muyOABbD04HLdwVeCjE" alt="Image of Cassandra Hradil">
                        </a>
                        <div>
                            <p class="name">Artist :: Cassandra Hradil</p>
                            <p class="Econdition">Environmental Condition: People can walk on oceans during storms</p>
                            <p class="Scondition">Storytelling Condition: All reading is tactile</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    </div>
</template>

<script>
    import MatCon01Header from "../../components/MatCon01-Header";
    import Footer from "../../components/Footer";
    import {PageFlip} from "page-flip";
    
    export default {
        name: "oceanwriting",
        components: {Footer, MatCon01Header},
        mounted() {
            this.pageFlip = new PageFlip(document.getElementById('book'),
                {
 
                    drawShadow: false,
                    width: 500, 
                    height: 750, 

                    size: "fixed",

                    mobileScrollSupport: false 
                }
            );

            this.pageFlip.loadFromHTML(document.querySelectorAll(".page"));
        },
        methods: {
            flipNextPage() {
                this.pageFlip.flipNext('top')
            },
            flipPrevPage() {
                this.pageFlip.flipPrev('top')
            }
        }
    }
</script>

<style scoped lang="scss">
    $Pink: #FF70E8;
    $Yellow: #FFF170;
    $BrightBlue: #8AC6D3;
    $Teal: #5C939F;
    $PostIt: #FFECBC;
    .project {
        margin: 20px auto;
    }
    
    .projectEmbed {
        justify-content: center;

    }
    #book{
        margin:auto;
        width:800px;
    }
    .page-image{
        background-size: 500px;
        width: 500px;
        height: 750px;

    }

    .flipInsContainer {
        display: flex;
        justify-content: space-between;
        padding: 0 10%;
        margin: -10px auto 0 auto;
        grid-gap: 10px;
        max-width: 1140px;
        width: 90%;
    }
    .flipIns {
        cursor: pointer;
        background-color: white;
        border-radius: 20px;
        padding: 6px 12px;
        color: black;
        font-weight: bold;
        font-family: "Cabin Sketch";
        white-space: nowrap;
        border: 2px solid black;
    }


</style>