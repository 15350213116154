<template>
    <div class="transitionPage">
        <MatCon01Header />
        <div id="PromptGenerator1">
            <MobileHeader/>
            <div class="buttonContainer">
                <h1 class="generatorButton" @click="generatePrompt">Generate A Prompt</h1>
            </div>
            <div class="whiteboard">
                <div></div>
                <div class="board">
                    <h2 class="conditionTitle">Environmental Condition</h2>
                    <div class="whiteboardCols">
                        <div class="boardLeft">
                            <div class="envPrompt"><p>{{ envPrompt }}</p></div>
                            <div class="drawing">
                                <img :src="imgChoice" alt="illustration">
                            </div>
                        </div>
                        <div class="boardRight">
                            <h2 class="plus">+</h2>
                            <h2 class="conditionTitle">Storytelling Condition</h2>
                            <div class="storyEquals">
                                <div class="storyPrompt"><p>{{ storyPrompt }}</p></div>
                                <h2 class="equals"> = ?</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="buttons">
<!--                    <router-link :to="{ name: 'Submit' }">-->
<!--                        <div class="submitButton">-->
<!--                            <p>Submit to the exhibition!</p>-->
<!--                        </div>-->
<!--                    </router-link>-->
                    <div class="saveButton" @click="generateImage(); showSave = true">
                        <p>Save and share your prompt!</p>
                    </div>
                </div>
            </div>
            <canvas id="myCanvas"></canvas>
            <div class="descriptionContainer">
                <h1 class="generatorTitle">Prompt Generator 01</h1>
                <p class="description">Created in January 2022 and curated by Cassandra Hradil, Marisa Parham, and
                    Andrew W. Smith. Prompts are derived from a mix of community submissions and conditions written by
                    the curators. Prompt Generator 01 was  used to curate the Material Conditions 01 exhibit as part of the Wrong Biennale  nº5.</p>
            </div>
            <transition name="modal">
                <SavePrompt v-if="showSave" v-on:closeSave="showSave = false" :genImage="imagePopup"/>
            </transition>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import Footer from "@/components/Footer";
    import MobileHeader from "@/components/MobileHeader";
    import SavePrompt from "@/components/SavePrompt";
    import MatCon01Header from "../components/MatCon01-Header";

    export default {
        name: 'Home',
        components: {MatCon01Header, MobileHeader, Footer, SavePrompt},
        data() {
            return {
                showSave: false,
                storyPrompt: "?",
                envPrompt: "?",
                imagePopup: null,
                imgChoice: "https://awikhigan.irlh.org/gen/wl/?id=nXYnbLV6qHWP3VOqj72ti6syOpUiNIJx",
                envArray: ["People can walk on oceans, but only during storms", "Computing systems are no longer manufactured, but grown into networks of fungus", "The earth stops spinning around its axis/core", "All plants can teleport", "We can remember our entire lives from the moment we’re born", "We figured out teleportation hotspots in certain trees.", "There is no longer any wind", "All four seasons happen each month", "Archival memory is stored in liquid form in the earth's oceans", "Plant life has become aggressive toward humans", "The movement of tectonic plates has rapidly increased", "Rain occurs on a set schedule determined by the Rain Institute", "Cats develop thumbs", "Dogs abandon humans", "The stars can no longer be seen", "Inland regions have become deserts. All communities are on coasts or islands.", "Rain no longer falls, but bubbles up from the ground.", "All planets in the Milky Way are habitable and there are free instant teleportation devices between them.", "All plants have perfect memory, and can communicate with humans.", "All humans see in grayscale, except for one week every year.", "Humans become nocturnal"],
                storyArray: ["Stories are now tattooed on the human body", "Stories must begin at their end", "Laughter actually cures all", "All stories must end where they began", "Uttered words turn into stones", "Stories do not have beginnings or ends, but are told in loops", "All stories are told in rhyming couplets", "There is no such thing as paper", "Reading is only tactile", "Books are perishable and only last for a year", "There are no longer writing systems and all storytelling is oral", "Only computer memory exists, humans forget everything when they sleep", "Only those under 15 years old and over 65 years old are allowed to tell stories.", "Everyone must publish a personal story on their birthday.", "Everyone publishes one book on any topic during their life. No more, no less.", "All stories must have multiple authors.", "Children are expected to master algebra before they are taught to read.", "Stories are transmitted through sound, but never using words.", "Stories told to conches last forever."],
                imgArray: ["https://awikhigan.irlh.org/gen/wl/?id=2UPmJx5yLVdTvO2EHOmhGAqKtziOwZZn", "https://awikhigan.irlh.org/gen/wl/?id=nXYnbLV6qHWP3VOqj72ti6syOpUiNIJx", "https://awikhigan.irlh.org/gen/wl/?id=woaAcWRNKwcoU4sbh1281Q6C13CaIPoP", "https://awikhigan.irlh.org/gen/wl/?id=txYwxdOG6nOx0cxjdyEvqjVkfCYxHgA4", "https://awikhigan.irlh.org/gen/wl/?id=C7iaR7eFE31spUQ2eq34roEOOmrsJ5gL", "https://awikhigan.irlh.org/gen/wl/?id=xVJHeXAnkn7WrI8ZjYEOKafyLD6TcVOw", "https://awikhigan.irlh.org/gen/wl/?id=ikSPE7TkSBM9f4ntZIIZaawAisaw9TwP", "https://awikhigan.irlh.org/gen/wl/?id=eUQTmUlnxzpe9Lp3PfOk5XefVcPiMJ2t"],
                postit: {
                    postitFont: "'Cabin Sketch', sans-serif",
                    postitSizeLg: "64px",
                    postitSizeMd: "48px",
                    postitSizeSm: "42px"
                }
            }
        },
        methods: {
            getRandomInt(min, max) {
                min = Math.ceil(min);
                max = Math.floor(max);
                return Math.floor(Math.random() * (max - min) + min);
            },
            generatePrompt() {
                let storyPick = this.getRandomInt(0, this.storyArray.length);
                let envPick = this.getRandomInt(0, this.envArray.length);
                let imgPick = this.getRandomInt(0, this.imgArray.length);

                this.postit.postitFont = "'Modern Love Caps', sans-serif";
                this.postit.postitSizeLg = "28px"
                this.postit.postitSizeMd = "22px"
                this.postit.postitSizeSm = "20px"

                this.storyPrompt = this.storyArray[storyPick];
                this.envPrompt = this.envArray[envPick];
                this.imgChoice = this.imgArray[imgPick];
            },
            wrapText(context, text, x, y, maxWidth, lineHeight) {
                var words = text.split(' ');
                var line = '';

                for (var n = 0; n < words.length; n++) {
                    var testLine = line + words[n] + ' ';
                    var metrics = context.measureText(testLine);
                    var testWidth = metrics.width;
                    if (testWidth > maxWidth && n > 0) {
                        context.fillText(line, x, y);
                        line = words[n] + ' ';
                        y += lineHeight;
                    } else {
                        line = testLine;
                    }
                }
                context.fillText(line, x, y);
            },
            generateImage() {
                // console.log(document.getElementsByClassName("storyPrompt")[0].innerText);
                let storyPrompt = document.getElementsByClassName("storyPrompt")[0].innerText;
                let envPrompt = document.getElementsByClassName("envPrompt")[0].innerText;
                //let img = document.getElementsByClassName("drawing")[0];

                let c = document.getElementById("myCanvas");
                c.width = c.getBoundingClientRect().width;
                c.height = c.getBoundingClientRect().height;

                let ctx = c.getContext("2d");
                ctx.clearRect(0, 0, c.width, c.height)

                ctx.fillStyle = '#FFF170';
                ctx.rect(0, 0, c.width, c.height);
                ctx.fill();

                let gradient = ctx.createLinearGradient(0, 0, c.width, 0);

                gradient.addColorStop(0, '#8AC6D3');
                gradient.addColorStop(.5, '#FF70E8');
                gradient.addColorStop(1, '#8AC6D3');

                ctx.strokeStyle = gradient;
                ctx.lineWidth = 10;
                ctx.strokeRect(10, 10, c.width - 20, c.height - 20);
                ctx.fillStyle = '#4B1873';
                let text = "Imagine a world where...  " + storyPrompt + "  &&  " + envPrompt + ".";
                if ( c.width === 500 ) {
                    ctx.font = "26px Roboto-Slab";
                    this.wrapText(ctx, text, 30, 50, c.width - 60, 25);

                } else {
                    ctx.font = "16px Roboto-Slab";
                    this.wrapText(ctx, text, 20, 35, c.width - 50, 15);
                }

                if ( c.width === 500 ) {
                    ctx.font = "18px Roboto-Slab";
                    ctx.fillText("https://materialconditions.salt.codes", c.width - 350, c.height - 30);

                } else {
                    ctx.font = "12px Roboto-Slab";
                    ctx.fillText("https://materialconditions.salt.codes", c.width - 230, c.height - 20);
                }
                // this is the "copy to clipboard" part that should perhaps go into a separate function
                //         c.toBlob(function (blob) {
                //             let data = [new window.ClipboardItem({[blob.type]: blob})];
                //             navigator.clipboard.write(data)
                //         });
                this.copyCanvasContentsToClipboard(c);
            },
            copyCanvasContentsToClipboard(canvas) {
                canvas.toBlob(function (blob) {
                    let data = [new window.ClipboardItem({[blob.type]: blob})];
                    // this.imagePopup = data;
                    // console.log('hello', data, 'goodbye', this.imagePopup)
                    navigator.clipboard.write(data)

                    //trying to get img to dom
                    var newImg = document.createElement('img'),
                        url = URL.createObjectURL(blob);

                    newImg.onload = function () {
                        // no longer need to read the blob so it's revoked
                        URL.revokeObjectURL(url);
                    };

                    newImg.src = url;
                    let container = document.getElementById("generatedImage");
                    container.appendChild(newImg);

                });
            },
        }
    }
</script>

<style scoped lang="scss">
    $Pink: #FF70E8;
    $Yellow: #FFF170;
    $BrightBlue: #8AC6D3;
    $Teal: #5C939F;
    $PostIt: #FFECBC;

    .transitionPage {
        background-color: $BrightBlue;
        min-height: 100vh;
        @media only screen and (max-width: 800px) {
            height: 100%;
        }
    }

    #PromptGenerator1 {
        width: 100%;
        min-height: 90vh;
        max-width: 1400px;
        margin: 0 auto;
        // overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media only screen and (max-width: 800px) {
            height: 100%;
        }
    }

    .buttonContainer {
        display: flex;
        justify-content: center;

        .generatorButton {
            padding: 0 24px;
            margin: 12px 0;
            font-family: "Modern Love Caps", sans-serif;
            font-size: 48px;
            font-weight: normal;
            color: $Pink;
            background-color: $Yellow;
            border: 5px solid $Pink;
            border-radius: 30px;
            text-align: center;
            transition: transform 250ms;
            cursor: pointer;
            user-select: none;
            @media only screen and (max-width: 1400px) {
                font-size: 42px;
            }
            @media only screen and (max-width: 800px) {
                font-size: 36px;
                border: 4px solid $Pink;
            }

            &:hover {
                animation-duration: .35s;
                animation-timing-function: linear;
                animation-name: wiggle;
                animation-iteration-count: infinite;
                animation-direction: alternate;
            }
        }
    }

    .whiteboard {
        display: grid;
        height: 40%;
        width: 100%;
        grid-template-columns: 10% 80% 10%;
        margin: 0 auto;
        grid-gap: 10px;
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: hidden;
        @media only screen and (max-width: 800px) {
            width: 90%;
            grid-template-columns: 1fr;
        }
    }

    .board {
        background-color: #f9f9f9;
        border: 2px solid black;
        border-radius: 40px;
        padding: 20px;
        overflow-y: hidden;

        @media only screen and (max-width: 800px) {
            height: 85vh;
        }

        .whiteboardCols {
            display: grid;
            grid-template-columns: 45% 55%;
            @media only screen and (max-width: 800px) {
                grid-template-columns: 100%;
                grid-gap: 20px;
            }
        }

        .boardLeft, .boardRight {
            display: flex;
            flex-direction: column;
            grid-gap: 20px;
            align-items: center;
        }
    }

    .storyEquals {
        display: flex;
        flex-direction: row;
        grid-gap: 40px;
        @media only screen and (max-width: 800px) {
            flex-direction: column;
        }
    }

    .conditionTitle {
        font-family: "Cabin Sketch", sans-serif;
        font-size: 52px;
        font-weight: normal;
        padding-bottom: 30px;
        margin: 0;
        @media only screen and (max-width: 1300px) {
            padding-bottom: 15px;
            font-size: 42px;
        }
        @media only screen and (max-height: 800px) {
            font-size: 36px;
            padding-bottom: 10px;
        }
        @media only screen and (max-width: 800px) {
            font-size: 26px;
            padding-bottom: 15px;
            justify-self: center;
            text-align: center;
        }
    }

    .envPrompt, .storyPrompt {
        background-color: $PostIt;
        width: 325px;
        height: 275px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 2px 4px 10px rgba(106, 106, 106, 0.45);

        @media only screen and (max-width: 1600px) {
            width: 275px;
            height: 225px;
        }
        @media only screen and (max-width: 1300px) {
            width: 225px;
            height: 175px;
        }
        @media only screen and (max-height: 1000px) {
            width: 250px;
            height: 175px;
        }
        @media only screen and (max-height: 600px) {
            width: 215px;
            height: 125px;
        }

        p {
            font-family: v-bind('postit.postitFont');
            font-size: v-bind('postit.postitSizeLg');
            margin: 0;
            padding: 20px;
            text-align: center;
            line-height: 1.15;
            @media only screen and (max-width: 1300px) {
                font-size: v-bind('postit.postitSizeMd');
            }
            @media only screen and (max-width: 800px) {
                font-size: v-bind('postit.postitSizeSm');
            }
            @media only screen and (max-height: 900px) {
                font-size: v-bind('postit.postitSizeMd');
            }
        }
    }

    .storyPrompt {
        transform: rotate(0.03turn);
        @media only screen and (max-width: 800px) {
            margin-top: -12px;
        }
    }

    .envPrompt {
        transform: rotate(-0.02turn);
    }

    .drawing {
        color: red;
        transform: rotate(-0.03turn);

        img {
            padding-top: 10px;
            width: 150px;
            @media only screen and (max-width: 1600px) {
                width: 125px;
            }
            @media only screen and (max-width: 1200px) {
                width: 100px;
            }
        }

        @media only screen and (max-width: 800px) {
            display: none;
        }
    }

    .plus, .equals {
        font-family: "Cabin Sketch", sans-serif;
        font-size: 72px;
        font-weight: normal;
    }

    .plus {
        align-self: start;
        margin: -50px 0 -45px 0;
        @media only screen and (max-width: 800px) {
            align-self: center;
            margin: -30px 0 -35px 0;
        }
    }

    .equals {
        align-self: center;
        white-space: nowrap;
        @media only screen and (max-width: 800px) {
            align-self: center;
            margin: -20px 0 0 0;
            font-size: 64px;
        }
        @media only screen and (max-height: 600px) {
            display: none;
        }
    }

    .descriptionContainer {
        width: 65%;
        background-color: $Teal;
        margin: 12px auto;
        padding: 8px 24px;
        @media only screen and (max-height: 1200px) {
            padding: 4px 16px;
        }
        @media only screen and (max-width: 800px) {
            width: 80%;
        }

        .generatorTitle {
            font-family: "Alioli", serif;
            font-size: 22px;
            color: white;
            margin: 10px 0;
            @media only screen and (max-height: 900px) {
                font-size: 20px;
            }
            @media only screen and (max-height: 800px) {
                font-size: 18px;
            }
            @media only screen and (max-width: 800px) {
                font-size: 18px;
            }
        }

        .description {
            font-family: "Roboto-Slab", serif;
            color: white;
            font-size: 16px;
            font-weight: normal;
            margin: 12px 0;
            @media only screen and (max-height: 800px) {
                font-size: 14px;
            }
            @media only screen and (max-width: 800px) {
                font-size: 14px;
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        grid-gap: 40px;
        justify-content: center;
        @media only screen and (max-width: 800px) {
            align-items: center;
        }

        a {
            text-decoration: none;
        }
    }

    .saveButton, .submitButton {
        max-width: 125px;
        background-color: $Teal;
        color: white;
        padding: 10px;
        margin-left: -30px;

        &:hover {
            cursor: pointer;
        }

        @media only screen and (max-width: 1400px) {
            max-width: 100px;
        }
        @media only screen and (max-width: 800px) {
            margin-left: 0;
        }

        p {
            font-size: 18px;
            @media only screen and (max-height: 800px) {
                font-size: 16px;
            }
        }
    }

    .saveButton {
        box-shadow: 4px 4px 0 $Pink;
        transform: rotate(0.01turn);
        transition: 250ms;

        &:hover {
            background-color: $Pink;
            box-shadow: 4px 4px 0 $Yellow;
            transform: rotate(-0.02turn);
        }
    }

    .submitButton {
        box-shadow: 4px 4px 0 $Yellow;
        transform: rotate(-0.01turn);
        transition: 250ms;

        &:hover {
            background-color: $Yellow;
            box-shadow: 4px 4px 0 $Pink;
            transform: rotate(0.02turn);
            color: $Teal
        }
    }

    canvas {
        width: 500px;
        height: 300px;
        position: absolute;
        top: 0;
        visibility: hidden;
        @media only screen and (max-width: 800px) {
            width: 300px;
            height: 150px;
        }
    }

    @keyframes wiggle {
        from {
            transform: rotate(0.01turn);
        }

        to {
            transform: rotate(-0.01turn);
        }
    }
</style>