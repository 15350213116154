<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="title">
                    <h1><span class="material">material</span><span class="condition">Condition</span><span
                        class="s">s</span></h1>
                </div>
                <div class="modal-container">
                    <div class="closeContainer">
                        <span class="closeModal" @click="$emit('closeModal')">X</span>
                    </div>
                    <div class="menu">
                        <div class="about">
                            <h2>About</h2>
                            <router-link :to="{ name: 'About' }" @click="$emit('closeModal')">
                                <h3>About the Project</h3>
                            </router-link>
                            <a href="https://salt.codes" target="_blank"><h3>Visit salt.codes</h3></a>
                            <a href="https://irlhumanities.org" target="_blank"><h3>Visit irLh</h3></a>
                        </div>
                        <div class="exhibits">
                            <h2>Material Conditions 01</h2>
                            <router-link :to="{ name: 'MC01Exhibit' }" @click="$emit('closeModal')">
                                <h3>Exhibit</h3>
                            </router-link>
                            <router-link :to="{ name: 'PromptGenerator01' }" @click="$emit('closeModal')">
                                <h3>Prompt Generator 01</h3>
                            </router-link>
<!--                            <router-link :to="{ name: 'Submit' }" @click="$emit('closeModal')">-->
<!--                                <h3>Submission Info</h3>-->
<!--                            </router-link>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "Nav",
        emits: ['closeModal']
    }
</script>

<style scoped lang="scss">
    $Pink: #FF70E8;
    $Yellow: #FFF170;
    $BrightBlue: #8AC6D3;
    $Teal: #5C939F;

    a {
        color: white;
        text-decoration: none;
        font-family: "Roboto-Slab", serif;
        font-size: 28px;
        @media only screen and (max-width: 800px) {
            font-size: 24px;
        }

        &:hover {
            color: $Yellow;
            cursor: pointer;
            text-decoration: underline $Pink;
            //font-family: "Alioli", serif;
            //font-size: 21px;
            @media only screen and (max-width: 800px) {
                font-size: 18px;
            }
        }
    }

    .closeContainer {
        display: flex;
        justify-content: flex-end;
    }

    .closeModal {
        background-color: #fff;
        color: #161616;
        font-size: 36px;
        height: 50px;
        width: 50px;
        cursor: pointer;
        border: 2px solid $Pink;
        border-radius: 60px;
        transition: .5s;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: monospace;
        position: absolute;
        top: 40px;
        right: 60px;
        @media only screen and (max-width: 800px) {
            font-size: 24px;
            height: 40px;
            width: 40px;
            top: 20px;
            right: 40px;
        }

        &:hover {
            transition: .4s;
            transform: scale(1.3);
            background-color: $Yellow;
        }
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: $Teal;
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        // vertical-align: middle;
        padding-top: 5%;
    }

    .modal-container {
        width: 60%;
        max-height: 80vh;
        overflow-y: auto;
        margin: 0 auto;
        border-top: 3px solid white;
        transition: all 0.3s ease;
        padding: 20px 40px;
        box-shadow: 2px 6px 20px rgba(0, 0, 0, 0.5);
        @media only screen and (max-width: 800px) {
            box-shadow: 0 0 0 rgba(0, 0, 0, 0);
            max-height: 100%;
            width: 85%;
        }

        h2, h3 {
            color: white;
            font-weight: normal;
            margin: 8px 0;
            @media only screen and (max-width: 800px) {
                margin: 4px 0;
            }
        }

        h2 {
            font-family: "Modern Love Caps", sans-serif;
            font-size: 36px;
            color: $Yellow;
            @media only screen and (max-width: 800px) {
                font-size: 32px;
            }
        }

        h3 {
            font-family: "Roboto-Slab", serif;
            font-size: 28px;
            margin-left: 30px;
            @media only screen and (max-width: 800px) {
                font-size: 24px;
            }

            &:hover {
                color: $Yellow;
                cursor: pointer;
                text-decoration: underline $Pink;
                //font-family: "Alioli", serif;
                //font-size: 21px;
                @media only screen and (max-width: 800px) {
                    font-size: 18px;
                }
            }
        }
    }

    .menu {
        //display: grid;
        //grid-template-columns: 1fr 1fr 1fr;
        //grid-gap: 20px;
        //@media only screen and (max-width: 800px) {
        //    justify-items: start;
        //    grid-template-columns: 1fr;
        //    grid-gap: 10px;
        //}

        .exhibits {
            justify-self: center;
            @media only screen and (max-width: 800px) {
                justify-self: start;
            }
        }

        .prompts {
            justify-self: end;
            @media only screen and (max-width: 800px) {
                justify-self: start;
            }
        }
    }

    .title {
        display: flex;
        justify-content: center;
    }

    h1 {
        display: inline-block;
        // background-color: $Pink;
        padding: 6px 12px;
        margin: 24px;
        color: white;
        font-weight: normal;

        .material, .s {
            font-family: "Modern Love Caps", sans-serif;
            font-size: 36px;
            @media only screen and (max-width: 800px) {
                font-size: 24px;
            }
        }

        .condition {
            font-family: "Alioli", serif;
            font-size: 56px;
            @media only screen and (max-width: 800px) {
                font-size: 36px;
            }
        }
    }
</style>