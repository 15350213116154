<template>
    <div class="transitionPage">
        <div id="letusbeweightless">
            <MatCon01Header/>
            <div class="project">
                <div class="titleArea">
                    <h1 class="title">Let Us Be Weightless</h1>
                    <p class="artistName">by Marisa Parham</p>
                </div>
                <div class="projectEmbed">
                    <iframe frameborder="0" height="760" src="https://weightless.mp285.com" width="1140"></iframe>
                    <p>This project begins with a ~1 minute micro-film. Your browser will navigate to a new URL with an interactive short-story upon completion of the micro-film.</p>
                </div>
                <div class="projectInfo">
                    <h2>Artist Statment</h2>
                    <p class="artistStatement"><i>Let Us Be Weightless</i> is an interactive short story. What you have in front of you is part one, which was first conceived in response to the <i>Material Conditions 01</i>. When I received my crowdsourced creation prompt, I was in the middle of teaching my <i>Deep Time: Memory, Media, and Ecological Imagination in the Americas</i> course. My prompt inspired me to rethink what we know about modern technological formations, particularly given that course's emphasis on attending to the diverse ecologics of people of color in the Americas.  </p>
                    <p class="artistStatement"><i>Let Us Be Weightless</i> is set in a world similar to our own, one that relies heavily on digital networks and their various underwater infrastructures. But in this world, network technologies carry vestiges of a very different kind of web. And in this world, stormtalkers like Shehn are valuable allies. Being unaligned, as stormtalkers have been for centuries, however, is becoming increasingly dangerous... </p>
                    <div class="person">
                        <a href="/mc-01/artists-curators#11">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=nQlKj9lJHZGbqfhPlxGNTpUUTmKceGQJ" alt="Image of Marisa Parham">
                        </a>
                        <div>
                            <p class="name">Artist :: Marisa Parham</p>
                            <p class="Econdition">Environmental Condition :: People can walk on oceans during storms</p>
                            <p class="Scondition">Storytelling Condition :: Stories told to conches last forever</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    </div>
</template>

<script>
    import MatCon01Header from "../../components/MatCon01-Header";
    import Footer from "../../components/Footer";
    
    export default {
        name: "LetUsBeWeightless",
        components: {Footer, MatCon01Header}
    }
</script>

<style scoped lang="scss">
    .project {
        margin: 20px auto;
    }

    .projectEmbed {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        max-width: 1440px;
        width: 90%;
        margin: 0 auto;
        p {
            max-width: 800px;
            text-align: center;
        }
    }
</style>