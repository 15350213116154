<template>
    <div class="transitionPage">
        <div id="doyousee">
            <MatCon01Header/>
            <div class="project">
                <div class="projContainer">
                    <div class="projectEmbed">
                        <div class="topSection">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=3NrStJRslyVIppuo5Y1LnYhX9A4ppKST">
                            <div>
                                <h1>Do You<br/>See, A<br/>Face<br/>On The<br/>Horizon?</h1>
                                <p>artwork and text<br/>by Shristi Singh</p>
                                <i class="material-icons audioBtn" v-if="!playing" @click="playVoice(); playing = true">play_circle_filled</i>
                                <i class="material-icons audioBtn" v-if="playing" @click="pauseVoice(); playing = false">pause_circle_filled</i>
                            </div>
                        </div>
                        <div class="sidebyside">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=ly9SNkwhvDgzzzvJvLdDMy3L8T8cVJo0" alt="Original artwork by Shristi Singh">
                            <pre>
There was a time
the world felt mine.
And now it was ending.
Oh, how I'd barely lived.

I begged the seas to give me more time.
I plead the mountains to hold their ground.
I yelled at the winds, '<i>please</i> slow down.'

My dreams would have played out over decades.
Or at least that's what I told myself.
But now I had minutes and the ocean didn't care.
It told me to store them in whatever I could find.

The ocean didn't realize it had given me a clue.
In its vastness, I saw every coast I'd ever been to.
<span style="text-decoration: line-through">There's something else you should know:</span>
<span style="text-decoration: line-through">I'm sentimental and I've got the trinkets to show.</span>
I've collected pendants and shells,
I've held onto conches and cones.
And now they would hold for me
all the dreams I'd plotted-both awake and asleep.

So I whispered and screamed
about making music and teaching kids,
about riding carousels and picnic dates.
I cried about my brother,
he deserves everything and more
My parents whom I loved so much,
there weren't enough conches in the world;
a world that had stopped spinning and also
left me dizzy from memories and hopes alike.

The conches told me, <span style="text-decoration: line-through">promised me</span>
they'd keep me safe, as the seas,
mountains, and winds finally gave in.
I was ready this time.

no No NO!
I won't die.
My stories found a home and so shall I.
                        </pre>
                        </div>
                    </div>
                </div>
                <div class="projectInfo">
                    <h2>Artist Statement</h2>
                    <p class="artistStatement">True to its all-pervasiveness, water has always found its way into the
                                               artist's most prized creations. So when the Storytelling Condition on the
                                               prompt generator read "Stories Told to Conches Last Forever," her
                                               imagination once again led her to the element she's felt closest to. In
                                               positioning herself at the end of the world (as interpreted from the
                                               Environmental Condition "the Earth stops spinning around its axis"), the
                                               artist declares herself one with it. <i>Do You See, A Face on the Horizon?</i>
                                               fields together staples of parallel universes, other worlds, and
                                               alternate realities she's often dreamed up to represent the only one
                                               she's ever known.</p>
                    <div class="person">
                        <a href="/mc-01/artists-curators#6">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=EHmQ0FdLR3cYuBVuqs9CsvTeS0hPCgZB" alt="Image of Shristi Singh">
                        </a>
                        <div>
                            <p class="name">Artist :: Shristi Singh</p>
                            <p class="Econdition">Environmental Condition :: the Earth stops spinning around its axis / core</p>
                            <p class="Scondition">Storytelling Condition :: Stories told to conches last forever</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    </div>
</template>

<script>
    import MatCon01Header from "../../components/MatCon01-Header";
    import Footer from "../../components/Footer";
    
    export default {
        name: "doyousee",
        components: {Footer, MatCon01Header},
        data() {
            return {
                playing: false,
                audio: new Audio('https://awikhigan.irlh.org/gen/wl/?id=W6TRTJWmCFlF379lpwUydHOtBBclRUAx')
            }
        },
        methods: {
            playVoice() {
                this.audio.currentTime = 0
                this.audio.play()
            },
            pauseVoice() {
                this.audio.pause()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .project {
        margin: 0 auto;
        
    }
    
    .projContainer {
        height: 100%;
        width: 100%;
        border-bottom: 5px solid #dd9c59;
        background-color: #F7E1CB;
        
    }
    
    .projectEmbed {
        max-width: 1140px;
        background-color: #F7E1CB;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: 0 auto;
        
    }
    
    .topSection {
        display: flex;
        flex-direction: row;
        grid-gap: 20px;
        max-width: 1140px;
        margin: 0 auto;
        padding: 20px 0;
        img {
            width: 65%;
            object-fit: contain;
            transform: rotate(180deg);
        }
        
        h1 {
            font-family: "AbrilFatFace", serif;
            font-size: 64px;
            color: #BF6A64;
            font-weight: normal;
            text-align: right;
        }
        
        p {
            font-family: "Josefin Slab", serif;
            font-size: 24px;
            color: #BF6A64;
            font-weight: normal;
            text-align: right;
        }
        div {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
    
            i {
                font-size: 64px;
                color: #BF6A64;
            }
        }
    }
    
    .sidebyside {
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding-bottom: 20px;
        img {
            height: 900px;
            object-fit: contain;
        }
        pre {
            font-family: "Josefin Slab", serif;
            font-size: 22px;
            color: #BF6A64;
        }
    }
    
    .audioBtn {
        &:hover {
            cursor: pointer;
        }
    }
</style>