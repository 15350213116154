<template>
    <div id="MobileHeader">
        <h1><span class="material">material</span><span class="condition">Condition</span><span
            class="s">s</span></h1>
    </div>
</template>

<script>
    export default {
        name: "MobileHeader"
    }
</script>

<style scoped lang="scss">
    $Teal: #5C939F;

    #MobileHeader {
        background-color: $Teal;
        color: white;

        h1 {
            text-align: center;
            margin: 6px 0;
        }

        @media only screen and (min-width: 800px) {
            display: none;
        }
    }

    .material, .s {
        font-family: "Modern Love Caps", sans-serif;
        font-size: 18px;
        font-weight: normal;
    }

    .condition {
        font-family: "Alioli", serif;
        font-size: 22px;
        font-weight: normal;
    }
</style>