<template>
    <div class="matCon01Header">
        <div class="header">
            <h1>Material Conditions 01</h1>
            <p class="tagline">Curated by Cassandra Hradil, Marisa Parham, and Andrew W. Smith for the Wrong
                               Biennial nº5</p>
        </div>
        <div class="submenu">
            <router-link :to="{ name: 'MC01Exhibit' }">
                <h2 class="menuItem">The Exhibit</h2>
            </router-link>
            <router-link :to="{ name: 'MC01About' }">
                <h2 class="menuItem">About</h2>
            </router-link>
            <router-link :to="{ name: 'Artists' }">
                <h2 class="menuItem">Artists & Curators</h2>
            </router-link>
            <router-link :to="{ name: 'PromptGenerator01' }">
                <h2 class="menuItem">Prompt Generator 01</h2>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MatCon01-Header"
    }
</script>

<style lang="scss" scoped>
    $Pink: #FF70E8;
    $Yellow: #FFF170;
    $BrightBlue: #8AC6D3;
    $Teal: #5C939F;
    $PostIt: #FFECBC;
    
    h1 {
        font-family: 'Alioli', serif;
        font-weight: normal;
        color: white;
        text-align: center;
        margin: 0;
    }
    
    a {
        text-decoration: none;
    }
    
    .header {
        background-color: #366671;
        padding: 16px 0;
    }
    
    .tagline {
        color: white;
        font-size: 18px;
        font-family: "Cabin Sketch", sans-serif;
        font-weight: normal;
        text-align: center;
        margin: 0;
    }
    
    .submenu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        grid-gap: 80px;
        margin: 0 auto;
        background-color: #233c47;
        
        @media only screen and (max-width: 900px) {
            grid-gap: 20px;
        }
        
        .menuItem {
            font-family: 'Alioli', serif;
            font-weight: normal;
            color: white;
            background-color: #5C939F;
            padding: 8px 12px;
            font-size: 18px;
            transition: .25s;
            text-align: center;
            @media only screen and (max-width: 900px) {
                font-size: 14px;
                padding: 4px 6px;
            }
            
            &:hover {
                background-color: $Yellow;
                color: $Pink;
                transform: rotate(5deg);
                transition: .25s
            }
        }
    }
</style>