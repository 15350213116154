<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="closeContainer">
                        <span class="closeModal" @click="$emit('closeSave')">X</span>
                    </div>
                    <h2>Your Prompt:</h2>
                    <div id="generatedImage">
						
					</div>
					<p>The image of your prompt above is copied to your clipboard! You can also right-click save it</p>
                    <p @click="$emit('closeSave')">Close</p>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "SavePrompt",
        emits: ['closeSave'],
        props: ['genImage']
    }
</script>

<style scoped lang="scss">
    $Pink: #FF70E8;
    $Yellow: #FFF170;
    $BrightBlue: #8AC6D3;
    $Teal: #5C939F;

    a {
        color: white;
        text-decoration: none;
        font-family: "Roboto-Slab", serif;
        font-size: 28px;
        @media only screen and (max-width: 800px) {
            font-size: 24px;
        }

        &:hover {
            color: $Yellow;
            cursor: pointer;
            text-decoration: underline $Pink;
            font-family: "Alioli", serif;
            font-size: 21px;
            @media only screen and (max-width: 800px) {
                font-size: 18px;
            }
        }
    }

    .closeContainer {
        display: flex;
        justify-content: flex-end;
    }

    .closeModal {
        background-color: #fff;
        color: #161616;
        font-size: 32px;
        height: 40px;
        width: 40px;
        cursor: pointer;
        border: 2px solid $Pink;
        border-radius: 60px;
        transition: .5s;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: monospace;
        position: relative;
        top: 10px;
        right: 30px;
        @media only screen and (max-width: 800px) {
            font-size: 24px;
            height: 30px;
            width: 30px;
        }

        &:hover {
            transition: .4s;
            transform: scale(1.3);
            background-color: $Yellow;
        }
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(256, 256, 256, 0.4);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 40%;
        max-height: 80vh;
        overflow-y: auto;
        margin: 0 auto;
        background-color: $Teal;
		border: 3px solid $Yellow;
        padding: 40px;
        transition: all 0.3s ease;
        @media only screen and (max-width: 800px) {
            max-height: 80%;
            width: 85vw;
            padding: 20px;
        }

        h2, h3 {
            color: white;
            font-weight: normal;
            margin: 8px 0;
        }

        h2 {
            font-family: "Modern Love Caps", sans-serif;
            font-size: 36px;
            color: $Yellow;
            @media only screen and (max-width: 800px) {
                font-size: 32px;
            }
        }

        h3 {
            font-family: "Roboto-Slab", serif;
            font-size: 28px;
            margin-left: 30px;
            @media only screen and (max-width: 800px) {
                font-size: 24px;
            }

            &:hover {
                color: $Yellow;
                cursor: pointer;
                text-decoration: underline $Pink;
                font-family: "Alioli", serif;
                font-size: 21px;
                @media only screen and (max-width: 800px) {
                    font-size: 18px;
                }
            }
        }
		p {
			color: white;
            @media only screen and (max-width: 800px) {
                font-size: 16px;
            }
		}
    }
</style>