<template>
    <div class="transitionPage">
        <div id="MC01Exhibit">
            <MatCon01Header/>
            <div class="titleSection">
                <h2><span class="glow">The</span> <span class="glow">Exh</span><span class="glow">ibit</span></h2>
                <div class="titleContainer">
                    <p>This exhibit is best viewed on laptop/desktop sized screens</p>
                    <p>Hover on project titles to preview; click to view the project</p>
                    <p>Scroll to the bottom for full list of projects</p>
                </div>
            </div>
            <div class="exhibit">
                <div class="grid">
                    <div class="div1">
                        <div class="postit left">
                            <span>There are no longer writing systems and all storytelling is oral</span>
                        </div>
                    </div>
                    <div class="div2">
                        <div class="postit left">
                            <span>Everyone must publish a personal story on their birthday</span>
                        </div>
                    </div>
                    <div class="div3">
                        <div class="postit left">
                            <span>Stories told to conches last forever</span>
                        </div>
                    </div>
                    <div class="div4">
                        <div class="postit left">
                            <span>Stories must begin at their end</span>
                        </div>
                    </div>
                    <div class="div5">
                        <div class="postit left">
                            <span>Stories do not have beginnings or ends, but are told in loops</span>
                        </div>
                    </div>
                    <div class="div6">
                        <div class="postit left">
                            <span>All reading is tactile</span>
                        </div>
                    </div>
                    <div class="div7">
                        <div class="postit bottom">
                            <span>We can remember our entire lives from the moment we were born</span>
                        </div>
                    </div>
                    <div class="div8">
                        <div class="postit bottom">
                            <span>Plant life has become aggressive toward humans</span>
                        </div>
                    </div>
                    <div class="div9">
                        <div class="postit bottom">
                            <span>All plants have perfect memory and can communicate with humans</span>
                        </div>
                    </div>
                    <div class="div10">
                        <div class="postit bottom">
                            <span>The stars can no longer be seen</span>
                        </div>
                    </div>
                    <div class="div11">
                        <div class="postit bottom">
                            <span>People can walk on oceans during storms</span>
                        </div>
                    </div>
                    <div class="div12">
                        <div class="postit bottom">
                            <span>All planets in the milky way are habitable and there a free instant teleportation devices between them</span>
                        </div>
                    </div>
                    <div class="div13">
                        <div class="postit bottom">
                            <span>The earth stops spinning around its axis/core</span>
                        </div>
                    </div>
                    <div class="div14 projBox" @click="$router.push('/mc-01/your-life-in-emoji')">
                        <p>Your Life in Emoji by Fred Wordie</p>
                        <img alt="Your Life in Emoji by Fred Wordie" class="signature" src="https://awikhigan.irlh.org/gen/wl/?id=54RsgIdsb3FKcmZTaI4l6OiPI7wx6gss">
                        <video src="https://awikhigan.irlh.org/gen/wl/?id=2Ac1GeshKPX6VktZjV5sfryqCm88zzOC" class="gifteaser" playsinline muted loop autoplay></video>
                    </div>
                    <div class="div15 projBox" @click="$router.push('/mc-01/the-pearl')">
                        <p>The Pearl by Christine Kemp and Amanda Licastro</p>
                        <img alt="The Pearl by Christine Kemp and Amanda Licastro" class="signature" src="https://awikhigan.irlh.org/gen/wl/?id=EN8b6ODqMFE80UcZ2YJ8rAjijvPY2yZj">
                        <video src="https://awikhigan.irlh.org/gen/wl/?id=87iqGhBQU2kXU7BxhQZcj3JtRIO3SYm1" class="gifteaser" playsinline muted loop autoplay></video>
                    </div>
                    <div class="div16 projBox" @click="$router.push('/mc-01/loops-return-to-the-beginning')">
                        <p>Loops + Return to the beginning by Paige Frasier</p>
                        <img alt="Loops + Return to the beginning by Paige Frasier" class="signature" src="https://awikhigan.irlh.org/gen/wl/?id=itO8X8SLlXzmEVjZCrZRogWWEOJVH2iL">
                        <video src="https://awikhigan.irlh.org/gen/wl/?id=Q9pRG4HOrALlrSxd7is2Lm5dqka0lUcE" class="gifteaser" playsinline muted loop autoplay></video>
                    </div>
                    <div class="div17 projBox" @click="$router.push('/mc-01/histree')">
                        <p>Histree by Andrew Smith</p>
                        <img alt="Histree by Andrew W. Smith" class="signature" src="https://awikhigan.irlh.org/gen/wl/?id=3y1UTBKyQW7BfT8QuehaOwRMDzlDb6Jm">
                        <video src="https://awikhigan.irlh.org/gen/wl/?id=0keFpnwHXzzTbZe8hdpOrEZ0jGP0ogGN" class="gifteaser" playsinline muted loop autoplay></video>
                    </div>
                    <div class="div18 projBox" @click="$router.push('/mc-01/two-heirlooms')">
                        <p>two heirlooms by Kalyani Tupkari</p>
                        <img alt="two heirlooms by Kalyani Tupkari" class="signature" src="https://awikhigan.irlh.org/gen/wl/?id=FRTqkiMkTKLTe4pMzS68xGbHxWNzz9jK">
                        <video src="https://awikhigan.irlh.org/gen/wl/?id=EbihZCJqatTvhRWhtPQXNsTLeHAiXTcU" class="gifteaser" playsinline muted loop autoplay></video>
                    </div>
                    <div class="div19 projBox" @click="$router.push('/mc-01/the-raisin-truck-makes-raisins')">
                        <p>The Raisin Truck Makes Raisins (version) by Daniel Lichtman</p>
                        <img alt="The Raisin Truck Makes Raisins (version) by Daniel Lichtman" class="signature" src="https://awikhigan.irlh.org/gen/wl/?id=YecYEtR6ytFf8Xg1Qw03NrtRD29hgim8">
                        <video src="https://awikhigan.irlh.org/gen/wl/?id=MtuXLszFqUnLvBLi5T7UsbE5nZbLUDuo" class="gifteaser" playsinline muted loop autoplay></video>
                    </div>
                    <div class="div20 projBox" @click="$router.push('/mc-01/let-us-be-weightless')">
                        <p>Let Us Be Weightless by Marisa Parham</p>
                        <img alt="Let Us Be Weightless by Marisa Parham" class="signature" src="https://awikhigan.irlh.org/gen/wl/?id=MUgyFrAtq8i6N67RfryqyONV46as965l">
                        <video src="https://awikhigan.irlh.org/gen/wl/?id=ARf83cKlnl868FsDanJwzAvQc39YcZIW" class="gifteaser" playsinline muted loop autoplay></video>
                    </div>
                    <div class="div21 projBox" @click="$router.push('/mc-01/ocean-writing')">
                        <p>Ocean Writing by Cassandra Hradil</p>
                        <img alt="Ocean Writing by Cassandra Hradil" class="signature" src="https://awikhigan.irlh.org/gen/wl/?id=89Zbc92pGRQNEKIdO8QkkPAEIWwxU181">
                        <video src="https://awikhigan.irlh.org/gen/wl/?id=SvV3mPMqCGaq872rXzoO56NswZRIWo4w" class="gifteaser" playsinline muted loop autoplay></video>
                    </div>
                    <div class="div22 projBox" @click="$router.push('/mc-01/inhabited')">
                        <p>Inhabited (free instant) by Julia V. Pretsfelder</p>
                        <img alt="Inhabited (free instant) by Julia V. Pretsfelder" class="signature" src="https://awikhigan.irlh.org/gen/wl/?id=ouG4vi11YqbuboUi5nmJLHDoDtzbwxQY">
                        <video src="https://awikhigan.irlh.org/gen/wl/?id=hev5hMXFmSXnO8agj4FW42FoNWYHkygA" class="gifteaser" playsinline muted loop autoplay></video>
                    </div>
                    <div class="div23 projBox" @click="$router.push('/mc-01/do-you-see-a-face-on-the-horizon')">
                        <p>Do You See, A Face On The Horizon? by Shristi Singh</p>
                        <img alt="Do You See, A Face On The Horizon? by Shristi Singh" class="signature" src="https://awikhigan.irlh.org/gen/wl/?id=rO6mMulzhvzDMK6OxQSthYTl97wpRkzk">
                        <video src="https://awikhigan.irlh.org/gen/wl/?id=eKn4sW6Xh7QnqnfVwRihLuE0VFVWvxkE" class="gifteaser" playsinline muted loop autoplay></video>
                    </div>
                    <div class="div24">
                        <img alt="Marker drawing of a small plant growing through earth" class="drawing" src="https://awikhigan.irlh.org/gen/wl/?id=txYwxdOG6nOx0cxjdyEvqjVkfCYxHgA4">
                    </div>
                    <div class="div25">
                        <img alt="Marker drawing of a crescent moon surrounded by stars" class="drawing" src="https://awikhigan.irlh.org/gen/wl/?id=woaAcWRNKwcoU4sbh1281Q6C13CaIPoP">
                    </div>
                    <div class="div26">
                    </div>
                    <div class="div27"></div>
                    <div class="div28"></div>
                    <div class="div29"></div>
                </div>
            </div>
            <div class="projectList">
                <div class="projectListGrid">
                    <div class="listGridHeader">
                        <h3>Project Title</h3>
                        <h3>Artist</h3>
                    </div>
                    <div @click="$router.push('/mc-01/do-you-see-a-face-on-the-horizon')">
                        <p>Do You See, A Face On The Horizon?</p>
                        <p>Shristi Singh</p>
                    </div>
                    <div @click="$router.push('/mc-01/histree')">
                        <p>Histree</p>
                        <p>Andrew W. Smith</p>
                    </div>
                    <div @click="$router.push('/mc-01/inhabited')">
                        <p>Inhabited (free instant)</p>
                        <p>Julia V. Pretsfelder</p>
                    </div>
                    <div @click="$router.push('/mc-01/let-us-be-weightless')">
                        <p>Let Us Be Weightless</p>
                        <p>Marisa Parham</p>
                    </div>
                    <div @click="$router.push('/mc-01/loops-return-to-the-beginning')">
                        <p>Loops + Return to the beginning</p>
                        <p>Paige Frasier</p>
                    </div>
                    <div @click="$router.push('/mc-01/ocean-writing')">
                        <p>Ocean Writing</p>
                        <p>Cassandra Hradil</p>
                    </div>
                    <div @click="$router.push('/mc-01/the-pearl')">
                        <p>The Pearl</p>
                        <p>Christine L. Kemp & Amanda Licastro, PhD.</p>
                    </div>
                    <div @click="$router.push('/mc-01/the-raisin-truck-makes-raisins')">
                        <p>The Raisin Truck Makes Raisins (version)</p>
                        <p>Daniel Lichtman</p>
                    </div>
                    <div @click="$router.push('/mc-01/two-heirlooms')">
                        <p>two heirlooms</p>
                        <p>Kalyani Tupkary</p>
                    </div>
                    <div @click="$router.push('/mc-01/your-life-in-emoji')">
                        <p>Your Life in Emoji</p>
                        <p>Fred Wordie</p>
                    </div>
                    <img alt="" class="bgDoodle" src="https://awikhigan.irlh.org/gen/wl/?id=nXYnbLV6qHWP3VOqj72ti6syOpUiNIJx">
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import Footer from "@/components/Footer";
    import MatCon01Header from "../components/MatCon01-Header";
    
    export default {
        name: "MC01Exhibit",
        components: {MatCon01Header, Footer}
    }
</script>

<style lang="scss" scoped>
    $Pink: #FF70E8;
    $Yellow: #FFF170;
    $BrightBlue: #8AC6D3;
    $Teal: #5C939F;
    $PostIt: #FFECBC;
    
    .transitionPage {
        background-color: #8AC6D3;
    }
    
    h1 {
        font-family: 'Alioli', sans-serif;
        font-weight: normal;
        color: white;
        text-align: center;
        margin: 0;
    }
    
    .titleSection {
        text-align: center;
        padding-top: 16px;
        
        .titleContainer {
            max-width: 550px;
            margin: 0 auto;
            background-color: #ebfeff;
            padding: 8px;
            border-top: 4px solid $Pink;
            border-bottom: 4px solid $Pink;
            border-right: 4px solid $Yellow;
            border-left: 4px solid $Yellow;
        }
        
        h2 {
            margin: 0;
            font-size: 48px;
            font-family: "Alioli", sans-serif;
            color: #000000;
        }
        
        p {
            margin: 0;
            font-size: 16px;
            font-family: "Roboto-Slab", serif;
        }
    }
    
    .exhibit {
        background-color: #f9f9f9;
        border: 2px solid black;
        border-radius: 40px;
        padding: 10px 20px;
        width: 85%;
        // height: 600px;
        margin: 16px auto 80px auto;
        
    }
    
    .grid {
        display: grid;
        grid-template-columns: 50px repeat(7, 1fr);
        grid-template-rows: repeat(6, 115px) 50px;
        grid-gap: 10px;
        
        @media only screen and (max-width: 1800px) {
            grid-template-rows: repeat(6, 80px) 50px;
        }
        
        @media only screen and (max-width: 1300px) {
            grid-template-rows: repeat(6, 70px) 50px;
        }
        
        div {
            height: 100%;
            align-self: center;
            justify-self: center;
            display: flex;
            align-items: center;
        }
    }
    
    .postit {
        font-family: "Cabin Sketch", sans-serif;
        font-weight: bold;
        font-size: 14px;
        max-width: 140px;
        height: 80px;
        background-color: #FFECBC;
        text-align: center;
        box-shadow: 1px 2px 5px rgba(80, 77, 77, 0.62);
        overflow: hidden;
        @media only screen and (max-width: 1800px) {
            max-width: 125px;
        }
        
        &.left {
            transform: translateX(-60px);
            width: 125px;
        }
        
        &.bottom {
            transform: translateY(40px);
            height: 90px;
        }
        
        span {
            padding: 4px;
        }
        
    }
    
    .signature {
        max-width: 105%;
        max-height: 110%;
        object-fit: contain;
        justify-self: center;
        align-self: center;
        z-index: 5;
    }
    
    .div1 {
        grid-area: 1 / 1 / 2 / 2;
        
        .postit {
            font-size: 13px;
            transform: translateX(-50px) rotate(-0.02turn);
            transition: 1s;
        }
        
        &:hover .postit {
            transform: translateX(-50px) scale(1.3) rotate(0turn);
            transition: 1s;
        }
    }
    
    .div2 {
        grid-area: 2 / 1 / 3 / 2;
        
        .postit {
            font-size: 14px;
            transform: translateX(-50px) rotate(0.005turn) translateX(15%);
            transition: 1s;
        }
        
        &:hover .postit {
            transform: translateX(-50px) scale(1.3) rotate(0turn) translateX(15%);
            transition: 1s;
        }
    }
    
    .div3 {
        grid-area: 3 / 1 / 4 / 2;
        
        .postit {
            font-size: 13px;
            transform: translateX(-50px) rotate(-0.002turn) translateX(-10%);
            transition: 1s;
        }
        
        &:hover .postit {
            transform: translateX(-50px) scale(1.3) rotate(0turn) translateX(-10%);
            transition: 1s;
        }
        
    }
    
    .div4 {
        grid-area: 4 / 1 / 5 / 2;
        
        .postit {
            font-size: 15px;
            transform: translateX(-50px) rotate(-0.015turn) translateX(5%);
            transition: 1s;
        }
        
        &:hover .postit {
            transform: translateX(-50px) scale(1.3) rotate(0turn) translateX(5%);
            transition: 1s;
        }
    }
    
    .div5 {
        grid-area: 5 / 1 / 6 / 2;
        
        .postit {
            font-size: 15px;
            transform: translateX(-50px) rotate(0.02turn);
            transition: 1s;
        }
        
        &:hover .postit {
            transform: translateX(-50px) scale(1.3) rotate(0turn);
            transition: 1s;
        }
    }
    
    .div6 {
        grid-area: 6 / 1 / 7 / 2;
        
        .postit {
            font-size: 16px;
            transform: translateX(-50px) rotate(-0.015turn) translateX(-5%);
            transition: 1s;
        }
        
        &:hover .postit {
            transform: translateX(-50px) scale(1.3) rotate(0turn) translateX(-5%);
            transition: 1s;
        }
    }
    
    .div7 {
        grid-area: 7 / 2 / 8 / 3;
        
        .postit {
            font-size: 14px;
            transform: translateY(40px) rotate(-0.01turn) translateY(5%);
            transition: 1s;
        }
        
        &:hover .postit {
            transform: translateY(40px) scale(1.3) rotate(0turn) translateY(5%);
            transition: 1s;
        }
    }
    
    .div8 {
        grid-area: 7 / 3 / 8 / 4;
        
        .postit {
            font-size: 14px;
            transform: translateY(40px) rotate(0.01turn) translateY(5%);
            transition: 1s;
        }
        
        &:hover .postit {
            transform: translateY(40px) scale(1.3) rotate(0turn) translateY(5%);
            transition: 1s;
        }
    }
    
    .div9 {
        grid-area: 7 / 4 / 8 / 5;
        
        .postit {
            font-size: 13px;
            transform: translateY(40px) rotate(-0.02turn);
            transition: 1s;
        }
        
        &:hover .postit {
            transform: translateY(40px) scale(1.3) rotate(0turn);
            transition: 1s;
        }
    }
    
    .div10 {
        grid-area: 7 / 5 / 8 / 6;
        
        .postit {
            font-size: 17px;
            transform: translateY(40px) rotate(0.01turn);
            transition: 1s;
        }
        
        &:hover .postit {
            transform: translateY(40px) scale(1.3) rotate(0turn);
            transition: 1s;
        }
    }
    
    .div11 {
        grid-area: 7 / 6 / 8 / 7;
        
        .postit {
            font-size: 16px;
            transform: translateY(40px) rotate(-0.01turn);
            transition: 1s;
        }
        
        &:hover .postit {
            transform: translateY(40px) scale(1.3) rotate(0turn);
            transition: 1s;
        }
    }
    
    .div12 {
        grid-area: 7 / 7 / 8 / 8;
        
        .postit {
            font-size: 11px;
            transform: translateY(40px) rotate(0.015turn);
            transition: 1s;
        }
        
        &:hover .postit {
            transform: translateY(40px) scale(1.3) rotate(0turn);
            transition: 1s;
        }
    }
    
    .div13 {
        grid-area: 7 / 8 / 8 / 9;
        
        .postit {
            font-size: 15px;
            transform: translateY(40px) rotate(-0.02turn);
            transition: 1s;
        }
        
        &:hover .postit {
            transform: translateY(40px) scale(1.3) rotate(0turn);
            transition: 1s;
        }
    }
    
    .projBox {
        p {
            display: none;
            font-family: Roboto Mono, monospace;
            z-index: 5;
            font-size: 18px;
            color: #ff24dd;
            text-decoration: underline;
            @media only screen and (max-width: 1500px) {
                font-size: 16px;
            }
        }
        
        .gifteaser {
            position: absolute;
            width: 1px;
            opacity: 0;
            z-index: 10;
            transition: .35s;
        }
        
        &:hover {
            cursor: pointer;
            
            p {
                display: block;
                max-width: 140px;
                z-index: 15;
                background-color: #ddf9ff;
            }
            
            .signature {
                display: none;
            }
        }
        
        h3 {
            font-family: "Modern Love Caps", sans-serif;
        }
    }
    
    .div14 {
        grid-area: 1 / 2 / 2 / 3;
        
        &:hover {
            .gifteaser {
                width: 250px;
                opacity: 100;
                transform: translateY(110px) translateX(100px);
                transition: .35s;
            }
        }
    }
    
    .div15 {
        grid-area: 3 / 2 / 4 / 3;
        
        &:hover {
            .gifteaser {
                width: 250px;
                opacity: 100;
                transform: translateY(75px) translateX(120px);
                transition: .35s;
            }
        }
    }
    
    .div16 {
        grid-area: 5 / 3 / 6 / 4;
        
        &:hover {
            .gifteaser {
                width: 250px;
                opacity: 100;
                transform: translateY(45px) translateX(-220px);
                transition: .35s;
            }
        }
    }
    
    .div17 {
        grid-area: 1 / 4 / 2 / 5;
        
        &:hover {
            .gifteaser {
                width: 250px;
                opacity: 100;
                transform: translateY(110px) translateX(100px);
                transition: .35s;
            }
        }
    }
    
    .div18 {
        grid-area: 3 / 4 / 4 / 5;
        
        &:hover {
            .gifteaser {
                width: 250px;
                opacity: 100;
                transform: translateY(110px) translateX(-200px);
                transition: .35s;
            }
        }
    }
    
    .div19 {
        grid-area: 4 / 5 / 5 / 6;
        
        &:hover {
            .gifteaser {
                width: 250px;
                opacity: 100;
                transform: translateY(110px) translateX(-200px);
                transition: .35s;
            }
        }
    }
    
    .div20 {
        grid-area: 3 / 6 / 4 / 7;
        
        &:hover {
            .gifteaser {
                width: 250px;
                opacity: 100;
                transform: translateY(110px) translateX(100px);
                transition: .35s;
            }
        }
        
    }
    
    .div21 {
        grid-area: 6 / 6 / 7 / 7;
        
        &:hover {
            .gifteaser {
                width: 250px;
                opacity: 100;
                transform: translateY(-110px) translateX(100px);
                transition: .35s;
            }
        }
    }
    
    .div22 {
        grid-area: 2 / 7 / 3 / 8;
        
        &:hover {
            .gifteaser {
                width: 250px;
                opacity: 100;
                transform: translateY(175px) translateX(-150px);
                transition: .35s;
            }
        }
    }
    
    .div23 {
        grid-area: 3 / 8 / 4 / 9;
        
        &:hover {
            .gifteaser {
                width: 250px;
                opacity: 100;
                transform: translateY(150px) translateX(-225px);
                transition: .35s;
            }
        }
    }
    
    .drawing {
        width: 75px;
        max-height: 50px;
        object-fit: contain;
    }
    
    .div24 {
        grid-area: 2 / 3 / 3 / 4;
    }
    
    .div25 {
        grid-area: 5 / 7 / 6 / 8;
    }
    
    
    .div26 {
        grid-area: 4 / 4 / 5 / 5;
    }
    
    .div27 {
        grid-area: 2 / 1 / 3 / 9;
    }
    
    .div28 {
        grid-area: 4 / 1 / 5 / 9;
    }
    
    .div29 {
        grid-area: 6 / 1 / 7 / 9;
    }
    
    .div27, .div28, .div29 {
        min-width: 100%;
        background-color: rgba(92, 147, 159, 0.04);
    }
    
    .projectList {
        background-color: #fff187;
        border-top: 6px solid $Pink;
        padding: 80px 0;
        
        .projectListGrid {
            background-color: #ebfeff;
            border-radius: 10px;
            border: 3px solid #234147;
            max-width: 1440px;
            width: 80%;
            margin: 0 auto;
            padding: 20px;
            
            div {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 10px;
                padding: 4px 8px;
                border: 2px solid rgba(54, 102, 113, 0);
                transition: 0.4s;
                border-radius: 16px;
                margin-bottom: 8px;
                
                &:hover:not(.listGridHeader) {
                    border: 2px solid #366671;
                    transition: 0.4s;
                    background-color: white;
                    cursor: pointer;
                }
            }
            
        }
        
        h3 {
            font-family: "Modern Love Caps", sans-serif;
            font-size: 28px;
            margin: 0;
        }
        
        p {
            margin: 0;
        }
    }
    
    .bgDoodle {
        position: relative;
        left: 75%;
        width: 110px;
    }

    $r: 12px; // glow radius
    $o: 8px; // glow offset
    $d: 1.5s; // duration
    $chars: 11; // # of letters

    $c: $Yellow $Pink $BrightBlue;

    .glow {
        animation: glow $d ease-in-out both infinite;
    
        @for $i from 1 through $chars {
            &:nth-child(#{$i}) {
                animation-delay: $i/$chars * -$d;
            }
        }
    }

    @mixin glow($c1, $c2, $c3) {
        text-shadow: 0 0 $r/6 snow,
        0 (-$o) $r $c3,
            (-$o) $o $r $c2,
        $o $o $r $c3;
    }

    @keyframes glow {
        0%, 100% {
            @include glow(nth($c, 1), nth($c, 2), nth($c, 3));
        }
        33% {
            @include glow(nth($c, 2), nth($c, 3), nth($c, 1));
        }
        66% {
            @include glow(nth($c, 3), nth($c, 1), nth($c, 2));
        }
    }
</style>