<template>
    <div class="transitionPage">
        <div id="histree">
            <MatCon01Header/>
            <div class="project">
                <div class="titleArea">
                    <h1 class="title">Histree</h1>
                    <p class="artistName">by Andrew W. Smith</p>
                </div>
                <div class="projectEmbed">
                    <iframe src="/Histree2.html" frameborder="0" class="histreeEmbed"></iframe>
                </div>
                <div class="projectInfo">
                    <h2>Artist Statement</h2>
                    <p class="artistStatement">Reflecting on these prompts brought me to thinking about the significance of trees to many written storytelling methods through the importance placed on paper as a medium. In a future without writing systems, what role would trees play in human storytelling? One prompt answered the other: if trees have perfect memory and can repeat our stories back to us, they would be great knowledge keepers given that their lifespan can span across several human generations. In a world where oral storytelling is the only method, could we sing to the trees and have them sing our stories back to us?</p>
                    <p class="artistStatement"><i>Histree</i> invites users to play with a virtual collage of a tree made from images of various plants as well as words on paper. By hovering, tapping, waiting, and dragging, you can uncover snippets of a story that hints at this speculative future where trees are the primary knowledge keepers for human society. The story that is hinted at explores whether humans would continue to trust trees to pass down their history from generation to generation. It is the beginning of an idea I hope to return to through an extension of this project.</p>
                    <div class="person">
                        <a href="/mc-01/artists-curators#11">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=BgHgAHD5gYUK2z7syxQWDCMb6GNTGnCS" alt="Image of Andrew W. Smith">
                        </a>
                        <div>
                            <p class="name">Artist :: Andrew W. Smith</p>
                            <p class="Econdition">Environmental Condition :: All plants have perfect memory and can communicate with humans</p>
                            <p class="Scondition">Storytelling Condition :: There are no longer writing systems and all storytelling is oral</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    </div>
</template>

<script>
    import MatCon01Header from "../../components/MatCon01-Header";
    import Footer from "../../components/Footer";
    
    export default {
        name: "Histree",
        components: {Footer, MatCon01Header}
    }
</script>

<style scoped lang="scss">
    .project {
        margin: 20px auto;
    }
    
    .projectEmbed {
        width: 80%;
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        h2 {
            font-family: "Modern Love Caps", sans-serif;
            text-align: center;
            font-size: 28px;
            margin-bottom: 8px;
        }
    }
    
    .histreeEmbed {
        width: 1240px;
        height: 800px;
    }
</style>