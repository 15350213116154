<template>
    <div class="transitionPage">
        <div id="twoheirlooms">
            <MatCon01Header/>
            <div class="project">
                <div class="titleArea">
                    <h1 class="title">two heirlooms</h1>
                    <p class="artistName">by Kalyani Tupkary</p>
                </div>
                <div class="projectEmbed">
                    <img src="https://awikhigan.irlh.org/gen/wl/?id=pHIux4PFygfNl3hJpsZcQTEE01piCGem" alt="two heirlooms" class="twoheirloomsImg">
                </div>
                <div class="projectInfo">
                    <h2>Artist Statement</h2>
                    <p class="artistStatement">As an immigrant from India currently residing in the United States, I am often divorced from the
                       social rhythms of festivities, holidays happening across continents. I worry when I forget words
                       in my mother tongue. I worry when I can’t remember the lyrics of some songs. I worry when I don’t
                       know how to prepare a traditional dish. I worry that I will lose the sounds, smells and touches
                       of my heritage. This discomfort of feeling disoriented with loved ones back home makes me hold
                       onto my food even more. Though technology increasingly mediates communication between people,
                       particularly when they live at a distance, it only facilitates the exchange of information. But
                       food - food can untether you, making you inhabit multiple places at the same time.</p>
                    <div class="person">
                        <a href="/mc-01/artists-curators#7">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=ounCWqahOoSFlQNK0rOFSL3OMSTyh4ke" alt="Image of Kalyani Tupkary">
                        </a>
                        <div>
                            <p class="name">Artist :: Kalyani Tupkary</p>
                            <p class="Econdition">Environmental Condition :: all plants have perfect memory and can communicate with humans</p>
                            <p class="Scondition">Storytelling Condition :: Stories told to conches last forever</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    </div>
</template>

<script>
    import MatCon01Header from "../../components/MatCon01-Header";
    import Footer from "../../components/Footer";
    
    export default {
        name: "twoheirlooms",
        components: {Footer, MatCon01Header}
    }
</script>

<style scoped lang="scss">
    .project {
        margin: 20px auto;
    }
    
    .projectEmbed {
        display: flex;
        justify-content: center;
        .twoheirloomsImg {
            width: 95%;
            max-height: 90vh;
            object-fit: contain;
        }
    }
</style>