<template>
    <div class="transitionPage">
        <div id="RaisinTruck">
            <MatCon01Header/>
            <div class="project">
                <div class="titleArea">
                    <h1 class="title">The Raisin Truck Makes Raisins (version)</h1>
                    <p class="artistName">by Daniel Lichtman</p>
                </div>
                <div class="projectEmbed">
                    <iframe frameborder="0" height="660" src="https://www.daniellichtman.com/materialconditions01/" width="960"></iframe>
                    <p>F = toggle fullscreen <br/> Escape = pause/leave game</p>
                </div>
                <div class="projectInfo">
                    <h2>Artist Statement</h2>
                    <p class="artistStatement">This quick project presents a series of visually abstract interactive scenes that loosely explore
                       the idea of stories that begin at their end within an imagined world in which stars can no longer
                       be seen—the prompts provided by the Salt Codes prompt generator. The project is an adaptation of
                       Lichtman’s project, <i>The Raisin Truck Makes Raisins</i>, a 3D game that uses collage, spatial
                       orientation and disorientation, and visual abstraction to reflect on the experience of caring for
                       young children during the pandemic. Interestingly, the premise for <i>Raisin Truck</i> seemed to lend
                       itself to the generated prompts — to visual narrative that telescopes nonlinearly across time,
                       and to a world in which both cosmic and material truths can be called into question. Both
                       projects are created with Lichtman’s Community Game Development Toolkit, a set of tools that make
                       it easy and fun for students, artists, researchers and community members to create their own
                       visually rich, interactive 3D environments and story-based games without the use of coding or
                       other specialized game-design skills.</p>
                    <div class="person">
                        <a href="/mc-01/artists-curators#4">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=gFMTJMmjPrVDovMGofwJSrMwB4QdYT7R" alt="Image of Daniel Lichtman">
                        </a>
                        <div>
                            <p class="name">Artist :: Daniel Lichtman</p>
                            <p class="Econdition">Environmental Condition: The stars can no longer be seen</p>
                            <p class="Scondition">Storytelling Condition: Stories must begin at their end</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    </div>
</template>

<script>
    import MatCon01Header from "../../components/MatCon01-Header";
    import Footer from "../../components/Footer";
    
    export default {
        name: "RaisinTruck",
        components: {Footer, MatCon01Header}
    }
</script>

<style lang="scss" scoped>
    .project {
        margin: 20px auto;
    }
    
    .projectEmbed {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        p {
            text-align: center;
        }
    }
</style>