<template>
	<div id="Footer">
		<div class="footerGrid">
			<div class="projectTitle"><h3><span class="material">material</span><span class="condition">Condition</span><span
				class="s">s</span></h3></div>
			<div class="poweredBy">
				<h3><span class="glow">powered</span> <span class="glow">by</span> <span class="glow">salt.codes</span> <span class="glow">&</span> <br/> <span class="glow">the</span> <span class="glow">immersive</span> <span class="glow">realities</span> <span class="glow">labs</span> <span class="glow">for</span> <span class="glow">the</span> <span class="glow">humanities</span></h3>
			</div>
			<div class="logos">
				<a href="https://salt.codes/" target="blank"><img
					alt="salt.codes logo" src="https://awikhigan.irlh.org/gen/wl/?id=ymAdB0xOEmlr9eTtuvMPEr1sNzqGzEdw"></a>
				<a href="https://irlhumanities.org/" target="blank"><img
					alt="irlhumanities logo" src="https://awikhigan.irlh.org/gen/wl/?id=cGmUz1Vl8HGTYKJoot69dVKVWheH7K1L"></a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Footer"
	}
</script>

<style lang="scss" scoped>
	$Pink: #FF70E8;
	$BrightBlue: #8AC6D3;
	$FadedPink: #C16DB4;
	$Yellow: #FFF170;
	$Purple: rgba(75, 24, 115, 0.65);
	
	#Footer {
		background-color: $FadedPink;
		height: 10vh;
		@media only screen and (max-width: 800px) {
			height: 100%;
		}
	}
	
	.footerGrid {
		height: 100%;
		width: 95%;
		margin: 0 auto;
		display: grid;
		grid-gap: 20px;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: center;
		justify-items: center;
		@media only screen and (max-width: 800px) {
			width: 99%;
			grid-template-columns: 100%;
			grid-gap: 10px;
		}
	}
	
	.projectTitle {
		@media only screen and (max-width: 800px) {
			display: none;
		}
		
		h3 {
			color: $Yellow;
			
			.material, .s {
				font-family: "Modern Love Caps", sans-serif;
				font-size: 18px;
				font-weight: normal;
			}
			
			.condition {
				font-family: "Alioli", serif;
				font-size: 22px;
				font-weight: normal;
			}
		}
	}
	
	.poweredBy {
		// background-color: #665C9F;
		white-space: pre;
		@media only screen and (max-width: 800px) {
			margin: 10px 0;
		}
		
		h3 {
			font-family: "Comodo", sans-serif;
			font-weight: 300;
			color: $Yellow;
			font-size: 18px;
			text-align: center;
			margin: 0;
			padding: 8px;
		}
	}
	
	.logos {
		display: flex;
		flex-direction: row;
		grid-gap: 40px;
		@media only screen and (max-width: 800px) {
			display: none;
		}
		
		img {
			width: 60px;
			height: 60px;
			transition: transform 250ms, scale 250ms;
		}
		
		img:hover {
			transform: translateY(-10px) scale(1.2);
		}
	}
	
	// glow
	$r: 12px; // glow radius
	$o: 8px; // glow offset
	$d: 1.5s; // duration
	$chars: 11; // # of letters
	
	$c: $Yellow $Pink $BrightBlue;
	
	.glow {
		animation: glow $d ease-in-out both infinite;
		
		@for $i from 1 through $chars {
			&:nth-child(#{$i}) {
				animation-delay: $i/$chars * -$d;
			}
		}
	}
	
	@mixin glow($c1, $c2, $c3) {
		text-shadow: 0 0 $r/6 snow,
		0 (-$o) $r $c3,
			(-$o) $o $r $c2,
		$o $o $r $c3;
	}
	
	@keyframes glow {
		0%, 100% {
			@include glow(nth($c, 1), nth($c, 2), nth($c, 3));
		}
		33% {
			@include glow(nth($c, 2), nth($c, 3), nth($c, 1));
		}
		66% {
			@include glow(nth($c, 3), nth($c, 1), nth($c, 2));
		}
	}
</style>