<template>
    <div class="transitionPage">
        <div id="thepearl">
            <MatCon01Header/>
            <div class="project">
                <div class="titleArea">
                    <h1 class="title">The Pearl</h1>
                    <p class="artistName">by Christine L. Kemp and Amanda Licastro, Ph.D.</p>
                </div>
                <div class="projectEmbed">
                    <div class="video">
                        <iframe allow="autoplay; fullscreen; picture-in-picture" allowfullscreen frameborder="0" src="https://player.vimeo.com/video/705851158?h=b4b06c9c0a" style="width:100%; height:100%;" title="The Pearl"></iframe>
                    </div>
                    <div class="transcript">
                        <h3>Video Transcript</h3>
                        <p>Imagine a future in which every child born is gifted a necklace that records their entire
                           lives, building an ever-growing, living archive of their existence? Would that child grow up
                           in a world that was safer –protected from abuse, mistreatment, abduction, and harm? Would
                           their families feel comfort in knowing they would have this record forever? Would humanity
                           benefit from having a constantly multiplying database of human experience? Or would humans
                           fundamentally, instinctively alter their behavior knowing they were under constant
                           surveillance? Would our concept of intimacy and privacy be rendered unknowable?</p>
                        <p>For this rapid protype we took the central image of the conch shell and married it with the
                           concept of recording memories. Fascinated with the natural ability of the conch to be used as
                           a tool to listen to sound as well as create new sound, we wanted to translate those
                           properties into a digital device inspired by near-future speculative design theory around
                           surveillance technologies.</p>
                        <p>Our prototype is meant to explore the structure of the shell, exploiting the eye-shape in the
                           middle of the spiral to house the camera lens, and to use the interior compartments as a
                           microphone and storage device. Influenced by the Fibonacci sequence inherent in the spirals
                           of a conch shell, we wanted the groves in the texture of the shell to act as a vinyl record,
                           increasing in storage capacity over time.</p>
                        <p>We drew from a variety of media sources, including Ursula’s necklace in The Little Mermaid,
                           (through which she captures Ariel’s voice), the “grain” technology depicted in “The Entire
                           History of You” episode of Black Mirror, as well as “SeeChange” from Dave Egger’s novel The
                           Circle.</p>
                        <p>To produce this prototype, we used Rhino which we manipulated and reconstructed to match our
                           2D sketches. To add more subtle design details, including the unique ID tag on the back, and
                           the microphone in the interior section. We also added the chain-link necklace and multiple
                           color options to give the sense that this is a retail product meant to be a popular gift item
                           for new parents who might have aesthetic preferences for their newborn. Our ultimate goal is
                           to 3D print this object as a 25 mm charm and attached it to a metal chain. We plan to implant
                           a tiny lens in the center of the spiral, and make the inside hollow so that we can play with
                           inserting tiny microphones to test the recording potential.</p>
                        <p>This process led to really interesting discussions about our current surveillance culture and
                           how we accept ever more intrusive technologies for the promise of personal health and
                           security. This thinking is influenced by the work of
                            <a href="https://www.dukeupress.edu/dark-matters" target="_blank">Simone Browne</a>,
                            <a href="https://link.springer.com/book/10.1057/9781137476661" target="_blank">Jill Walker
                                                                                                           Rettberg</a>,
                           and
                            <a href="https://danyaglabau.com/portfolio/bill-of-health/" target="_blank">danya glabau</a>.
                           We hope it is equally generative for the audience of Material Conditions.</p>
                    </div>
                    <div class="transcript">
                        <p>Video Citations (Imagery)</p>
                        <p>“The Circle (Egger’s novel).” Wikipedia. Feb. 2022.<br/>https://en.wikipedia.org/wiki/The_Circle_(Eggers_novel)
                        </p>
                        <p>“The Entire History of You.” Wikipedia. Feb. 2022.<br/>https://en.wikipedia.org/wiki/The_Entire_History_of_You
                        </p>
                        <p>“Ursula’s necklace.” The Disney Wiki. Feb. 2022.<br/>https://disney.fandom.com/wiki/Ursula%27s_Necklace
                        </p>
                    </div>
                    <div class="threeOb">
                        <div class="sketchfab-embed-wrapper">
                            <iframe allow="autoplay; fullscreen; xr-spatial-tracking" allowfullscreen execution-while-not-rendered execution-while-out-of-viewport frameborder="0" height="520" mozallowfullscreen="true" src="https://sketchfab.com/models/236510534fd4424fbbb2951225869e56/embed" title="The Pearl" web-share webkitallowfullscreen="true" width="760" xr-spatial-tracking></iframe>
                            <p style="font-size: 13px; font-weight: normal; margin: 5px; color: #4A4A4A;">
                                <a href="https://sketchfab.com/3d-models/the-pearl-236510534fd4424fbbb2951225869e56?utm_medium=embed&utm_campaign=share-popup&utm_content=236510534fd4424fbbb2951225869e56" style="font-weight: bold; color: #1CAAD9;" target="_blank">
                                    The Pearl </a> by
                                <a href="https://sketchfab.com/salt.codes?utm_medium=embed&utm_campaign=share-popup&utm_content=236510534fd4424fbbb2951225869e56" style="font-weight: bold; color: #1CAAD9;" target="_blank">
                                    salt.codes </a> on
                                <a href="https://sketchfab.com?utm_medium=embed&utm_campaign=share-popup&utm_content=236510534fd4424fbbb2951225869e56" style="font-weight: bold; color: #1CAAD9;" target="_blank">Sketchfab</a>
                            </p></div>
                    </div>
                    <div class="imagesContainer">
                        <h3>Textured Renderings of the 3D Model</h3>
                        <div class="stillImages">
                            <img alt="" src="https://awikhigan.irlh.org/gen/wl/?id=0Rn9LOoHyO80BNIZ1m0S6p0kbqbJUAEx">
                            <img alt="" src="https://awikhigan.irlh.org/gen/wl/?id=enyll54FSinAUyUhFaRoinjiTNJBTduo">
                            <img alt="" src="https://awikhigan.irlh.org/gen/wl/?id=ix56xbJY2Jtd5Ouwpv9sJ7yl5Mll6Q0P">
                            <img alt="" src="https://awikhigan.irlh.org/gen/wl/?id=p9BYwPEUNb5EKiO5la9uMlbjlcmVZZm7">
                            <img alt="" src="https://awikhigan.irlh.org/gen/wl/?id=FqzLh0K4MTeFVn7WTi33oh8uOJhFANPh">
                            <img alt="" src="https://awikhigan.irlh.org/gen/wl/?id=wuXfPu8dzDBEFB15islIW1nHVKiprtus">
                        </div>
                    </div>
                </div>
                <div class="projectInfo">
                    <h2>Artist Statement</h2>
                    <p class="artistStatement">For this rapid prototype we took the central image of the conch shell and
                                               married it with the concept of recording memories. Fascinated with the
                                               natural ability of the conch to be used as a tool to listen to sound as
                                               well as create new sound, we wanted to translate those properties into a
                                               digital device inspired by near-future speculative design theory around
                                               surveillance technologies. The result is a small necklace to be gifted to
                                               a newborn and worn throughout the entire life of a person, recording
                                               every "memory" through video and sound surveillance. Our prototype is
                                               meant to explore the structure of the shell, exploiting the eye-shape in
                                               the middle of the spiral to house the camera lens, and to use the
                                               interior compartments as a microphone and storage device. Influenced by
                                               the Fibonacci sequence inherent in the spirals of a conch shell, we
                                               wanted the groves in the texture of the shell to act as a vinyl record,
                                               increasing in storage capacity over time. This process led to really
                                               interesting discussions about our current surveillance culture and how we
                                               accept ever more intrusive technologies for the promise of personal
                                               health and security. This thinking is influenced by the work of
                        <a href="https://www.dukeupress.edu/dark-matters" target="_blank">Simone Browne</a>,
                        <a href="https://link.springer.com/book/10.1057/9781137476661" target="_blank">Jill Walker
                                                                                                       Rettberg</a>,
                                               and
                        <a href="https://danyaglabau.com/portfolio/bill-of-health/" target="_blank">danya glabau</a>. We
                                               hope it is equally
                                               generative for the audience of Material Conditions.</p>
                    <div class="person">
                        <div>
                            <a href="/mc-01/artists-curators#2">
                                <img alt="Image of Christine L. Kemp" src="https://awikhigan.irlh.org/gen/wl/?id=A3P5WdPVWdBYKuy6Swixt7IbT5WLR4YS">
                            </a>
                            <a href="/mc-01/artists-curators#3">
                                <img alt="Image of Amanda Licastro, Ph.D." src="https://awikhigan.irlh.org/gen/wl/?id=eN94qqvtJrsMRatM9MBhYvYNpHJlAsk1">
                            </a>
                        </div>
                        <div>
                            <p class="name">Artists :: Christine L. Kemp & Amanda Licastro, Ph.D.</p>
                            <p class="Econdition">Environmental Condition :: We can remember our entire lives from the
                                                  moment we
                                                  were born</p>
                            <p class="Scondition">Storytelling Condition :: Stories told to conches last forever</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    </div>
</template>

<script>
    import MatCon01Header from "../../components/MatCon01-Header";
    import Footer from "../../components/Footer";
    
    export default {
        name: "ThePearl",
        components: {Footer, MatCon01Header}
    }
</script>

<style lang="scss" scoped>
    
    #pearlFrame {
        height: 750px;
        width: 800px;
    }
    
    .project {
        margin: 20px auto;
    }
    
    .projectEmbed {
        width: 80%;
        max-width: 1440px;
        margin: 0 auto;
    }
    
    .video {
        height: 600px;
    }
    
    h3 {
        font-family: "Roboto-Slab", serif;
        font-size: 22px
    }
    
    .transcript {
        width: 80%;
        max-width: 1440px;
        margin: 0 auto;
        
        
        p {
            font-size: 16px;
        }
    }
    
    .imagesContainer {
        width: 80%;
        max-width: 1440px;
        margin: 0 auto;
    }
    
    .stillImages {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        flex-wrap: wrap;
        padding-bottom: 40px;
        
        img {
            width: 425px;
            border: 1px solid black;
        }
    }
    
    .threeOb {
        display: flex;
        justify-content: center;
        padding: 40px 0;
    }
</style>