<template>
    <div class="transitionPage">
        <MatCon01Header/>
        <div class="aboutPage">
            <h1 class="title">About the Exhibit</h1>
            <p>What would eventually become <i>Material Conditions 01</i> emerged from the persistent sense of urgency
               and crisis that increasingly compels creative and artistic communities to respond to the now and to be
               guided by the need for “change,” “impact,” and “thinking big.” With <i>Material Conditions 01</i>, we
               wanted to take a moment to alleviate the ongoing pressure that comes from the sense that every creation
                <i>must</i> or <i>should</i> solve a grand problem or address a vital social issue.</p>
            <p>How might we respond to the future? To each other’s ideas about the future? To ideas about the future that are playful, embracing the excitement of thinking about concepts we cannot fully grasp?</p>
            <p>How do we make spaces to engage projects that are small, personal, and experimental? In creating space for these types of projects, <i>Material Conditions 01</i> is itself a small experiment. Our goal was not to discourage artistic and creative work that is challenging or ambitious in scope, but rather to hold space with our fellow makers and thinkers in ways that embrace fantasy, playfulness and mischief. A space for small experiments that remind ourselves of the personal and local stakes we all hold in political action and social transformation.
            </p>
            <p>The exhibit began with <span class="generator" @click="$router.push('/mc-01/prompt-generator-01')">Prompt Generator 01</span>,
               a community-generated collection of speculative prompts to inspire each project in the exhibit. We invited the submission of “environmental conditions” and “storytelling conditions.” Each suggested prompt was a spark of imagination that reflected participants’ unique interpretation of “environmental” and “storytelling.” Prompt Generator 01 remains available, alongside the exhibit inspired by it, to continue to foster speculative creations. </p>
            <p>The ten projects that make up <i>Material Conditions 01</i> come from an international collection of
               artists, creative technologists, scholars, and makers deeply engaged with materiality, ecology, and
               storytelling. Each project introduces us to imagined futures and alternate realities through a variety of mediums and material forms that invite us to think differently about our relationship with the natural world and each other.</p>
            <h2 class="section">Materiality :: </h2>
            <p>Attentiveness to material properties is a through-line of Material Conditions 01, both within the projects’ construction and the themes that they explore. <a class="name" href="/mc-01/artists-curators#2">Christine L. Kemp</a> and <a class="name" href="/mc-01/artists-curators#3">Amanda Licastro</a>’s
                <span class="project" @click="$router.push('/mc-01/the-pearl')">The Pearl</span> gives form to futuristic objects through 3D modeling, while imagining a world in which memories, made material, can never be lost — for better or worse. Memory is made material in a different
                                                           way in <a class="name" href="/mc-01/artists-curators#8">Fred Wordie</a>’s
                <span class="project" @click="$router.push('/mc-01/your-life-in-emoji')">Your Life in Emoji</span>, wherein emoji are provided to the user as a form to imagine their pasts and futures. Wordie’s exploration is made all the more compelling by the ability to see and attempt to interpret the emoji futures of others. <a class="name" href="/mc-01/artists-curators#7">Kalyani Tupkary</a>’s <span class="project" @click="$router.push('/mc-01/two-heirlooms')">two heirlooms</span> fragments the material properties of word and image to draw tension between telling stories and listening to them. She uses the material relationship between two natural forms, kadipatta and conch, to tease out the bonds between people, cultures, and objects across space and time. Taken together, these works interrogate the role of materialities in our imagined futures and our relationship to ecological ways of knowing. </p>
            <h2 class="section">Ecologies :: </h2>
            <p>The projects in <i>Material Conditions 01</i> reflect the shifting relations of words and images within the context of speculative ecologies. <a class="name" href="/mc-01/artists-curators#6">Shristi Singh</a> crafts language and shapes into an emotive speculation on the end of the world. Her piece, <span class="project" @click="$router.push('/mc-01/do-you-see-a-face-on-the-horizon')">Do You See, A Face On the Horizon?</span>, renders ecological disaster through a moment of personal disaster, questioning whether the persistence of our stories can keep us here, even as the world falls apart.  In <span class="project" @click="$router.push('/mc-01/loops-return-to-the-beginning')">Loops</span> and <span class="project" @click="$router.push('/mc-01/loops-return-to-the-beginning')">Return to the beginning</span>, <a class="name" href="/mc-01/artists-curators#1">Paige Frasier</a> brings together handcrafted haiku with AI-generated images to co-create a human + machine perspective on nature and storytelling. Frasier’s work reflects on the material aspects of the natural world, with word and image as an interpretive medium. </p>
            <h2 class="section">Storytelling (the celestial) :: </h2>
            <p>Every piece in Material Conditions 01 is an act of storytelling, adapting experimental techniques to push the boundaries of what forms a story might take. <a class="name" href="/mc-01/artists-curators#4">Daniel Lichtman</a>’s <span class="project" @click="$router.push('/mc-01/the-raisin-truck-makes-raisins')">The Raisin Truck Makes Raisins (version)</span> presents us with a starless celestial skyline in a virtual 3D environment, crawling with nonlinear forms. The creeping geometries and rapidly changing scenery visually navigate us through a story without a clear sense of beginning or end. <a class="name" href="/mc-01/artists-curators#5">Julia V. Pretsfelder</a>’s <span class="project" @click="$router.push('/mc-01/inhabited')">Inhabited (free instant)</span> mingles words freely with her imaged compositions to meditate on personal loss and celestial navigation. Her manipulation of the verbal and visual blurs the lines between the two, unfolding a layered narrative that explores experimental approaches to storytelling. </p>
            <p>As curators, we responded to the same prompt generator with our own meditations on storytelling and the environment. <a class="name" href="/mc-01/artists-curators#9">Cassandra Hradil</a>’s <span class="project" @click="$router.push('/mc-01/ocean-writing')">Ocean Writing</span> interweaves the tactile with the virtual to illustrate the beginnings of a future in which storytelling becomes a touch-based process, recorded and shared through the knots and beads of textiles. <a class="name" href="/mc-01/artists-curators#10">Marisa Parham</a>’s <span class="project" @click="$router.push('/mc-01/let-us-be-weightless')">Let Us Be Weightless</span> is an interactive narrative exploration that reverse-imagines the materiality of (our) world’s undersea network infrastructure as a circum-Atlantic story about humans, technology, and sea creatures. <a class="name" href="/mc-01/artists-curators#11">Andrew W. Smith</a>’s <span class="project" @click="$router.push('/mc-01/histree')">Histree</span> uses interactivity, collage, and disrupted temporality to offer glimpses of a world in which trees become the primary keeper of history across generations.</p>
            <p><i>Material Conditions 01</i> is the first in a series of experimental prompt generators & art exhibits. Like a call and response, the exhibit is equally concerned with the creative process sparked for each contributor by the prompt generator, as well as the finished pieces. We look forward to creating more spaces for small, experimental futures, and hope you will join us in them.</p>
            <p class="sigs">— Cassandra Hradil, Marisa Parham, and Andrew W. Smith</p>
            <p class="role">Curators of <i>Material Conditions 01</i></p>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import Footer from "@/components/Footer";
    import MatCon01Header from "../components/MatCon01-Header";
    
    export default {
        name: "MC01About",
        components: {MatCon01Header, Footer},
    }
</script>

<style lang="scss" scoped>
    $Pink: #FF70E8;
    $Yellow: #FFF170;
    $BrightBlue: #8AC6D3;
    $Teal: #5C939F;
    $PostIt: #FFECBC;
    
    .transitionPage {
    
    }
    
    .aboutPage {
        height: 100%;
        width: 90%;
        max-width: 1140px;
        margin: 0 auto;
        padding-bottom: 40px;
    }
    
    a {
        text-decoration: none;
        color: $Yellow;
        
        &:hover {
            color: $Pink;
        }
    }
    
    p {
        color: black;
    }
    
    h2 {
        font-family: "Cabin Sketch", sans-serif;
        font-size: 22px;
        margin-bottom: 16px;
    }
    
    .title {
        font-family: "Modern Love Caps", sans-serif;
        font-size: 45px;
        font-weight: normal;
        margin: 12px 0;
    }
    
    .generator {
        font-weight: bold;
        color: #593a99;
    
        &:hover {
            text-decoration: underline;
            cursor: pointer;
    
        }
    }
    
    .name {
        color: #009cd9;
        
        &:hover {
            text-decoration: underline;
            cursor: pointer;
            color: #006f99;
        }
    }
    
    .project {
        font-style: italic;
        color: #ff3fe1;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
            color: #c300a2;
        }
    }
    
    .sigs, .role {
        text-align: right;
        margin: 0;
    }
    .sigs {
        font-size: 22px;
    }
    .role {
        font-size: 18px;
    }

</style>