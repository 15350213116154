<template>
    <div class="menuButton" @click="showModal = true">
        <i class="material-icons">menu</i>
    </div>
    <transition name="modal">
        <Nav v-if="showModal" v-on:closeModal="showModal = false"/>
    </transition>
    <div class="totalPage">
        <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
                <component :is="Component"/>
            </transition>
        </router-view>
    </div>
</template>

<script>
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";

    export default {
        components: {Footer, Nav},
        data() {
            return {
                showModal: false
            }
        },
    }
</script>

<style lang="scss">
    $Pink: #FF70E8;
    $Yellow: #FFF170;
    $BrightBlue: #8AC6D3;
    $Teal: #5C939F;

    @import url("https://use.typekit.net/xds3rex.css");

    @font-face {
        font-family: "Alioli";
        src: url('assets/Alioli-Regular.woff');
        font-style: normal;
    }

    @font-face {
        font-family: "Modern Love Caps";
        src: url('assets/ModernLove-Caps.ttf');
        font-style: normal;
    }

    @font-face {
        font-family: "Cabin Sketch";
        src: url('assets/CabinSketch-Regular.ttf');
        font-style: normal;
    }

    @font-face {
        font-family: "Comodo", sans-serif;
        font-weight: 400;
        font-style: normal;
        src: url('assets/comodo.otf')
    }

    @font-face {
        font-family: "Roboto-Slab", serif;
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Josefin Slab";
        src: url('assets/JosefinSlab-Medium.ttf');
    }
    
    @font-face {
        font-family: "AbrilFatFace";
        src: url('assets/AbrilFatface-Regular.ttf');
    }

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 100vh;
    }

    html {
        scroll-behavior: smooth;
        cursor: url("https://awikhigan.irlh.org/gen/wl/?id=TLz2XQxAsrIoGcQ2Q37rcu7zQU10tRIG"), default;
    }

    .totalPage {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
    }

    .transitionPage {
        height: 100%;
        flex-grow: 1;
    }

    p {
        font-family: 'roboto-slab', serif;
        font-weight: 300;
        font-size: 20px;
    }

    body {
        margin: 0;
    }

	.titleArea {
		text-align: center;
		margin: 40px 0;
		.title {
			font-family: "Cabin Sketch", sans-serif;
			font-size: 48px;
			font-weight: normal;
			margin: 0;
		}
		.artistName {
			font-family: "Cabin Sketch", sans-serif;
			font-size: 24px;
			font-weight: bold;
			margin: 0;
		}
	}

	.projectInfo {
		width: 80%;
        max-width: 1140px;
		margin: 0 auto;
  
		h2 {
			font-family: "Alioli", serif;
			font-weight: normal;
            font-size: 22px;
		}
		.center {
			display: flex;
			justify-content: center;
		}

        p {
            font-family: "Modern Love Caps", sans-serif;
            font-size: 24px;
        }
        
        .artistStatement {
            font-family: 'roboto-slab', serif;
            font-weight: 300;
            font-size: 18px;
        }
        
        .person {
            display: flex;
            flex-direction: row;
            grid-gap: 20px;
            padding-bottom: 10px;
            img {
                width: 200px;
                height: 200px;
                object-fit: contain;
                border-radius: 200px;
                border: 5px solid rgba(92, 147, 159, 0);
                &:hover {
                    border: 5px solid #5C939F;
                    cursor: pointer;
                }
            }
        }
        
        .Econdition {
            color: #00ab45;
        }
        
        .Scondition {
            color: #eea600;
        }
	}

    .modal-enter {
        opacity: 0;
    }

    .modal-enter-active, .modal-leave-active {
        opacity: 0;
        transition: opacity .75s;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .menuButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        padding: 8px;
        position: fixed;
        top: 30px;
        right: 60px;
        z-index: 5;
        background-color: $Pink;
        border-radius: 200px;
        border: 3px solid $Yellow;
        cursor: pointer;
        color: $Yellow;
        @media only screen and (max-width: 800px) {
            top: auto;
            width: 30px;
            height: 30px;
            bottom: 20px;
            right: 30px;
        }
        i {
            font-size: 32px;
        }

        transition: transform 250ms;
    }

    .menuButton:hover {
        transform: scale(1.1);
    }
</style>
